import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const styles = {
  root: {
    flexGrow: 1,
    height:'100%',
    width: '100%',
  },
  progress: {
    width: 150,
    height: 10,
    borderRadius: 5,
    backgroundColor: 'rgba(255,255,255,.5)'
  }
};

class LinearDeterminate extends Component {
  render() {
    const { classes, completed, width, isSpanish } = this.props;
    return (
      <div className={classes.root}>
      <Typography variant='body2' >
        {`${completed}% ${isSpanish === true ? 'Completado' : 'Completed'}`}
      </Typography>
        <LinearProgress style={{width}} className={classes.progress} color="primary" variant="determinate" value={completed} />
      </div>
    );
  }
}

LinearDeterminate.propTypes = {
  classes: PropTypes.object.isRequired,
  completed: PropTypes.number.isRequired
};

export default withStyles(styles)(LinearDeterminate);