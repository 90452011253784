import 'babel-polyfill';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import promiseFinally from 'promise.prototype.finally';
import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import qs from 'qs';
import App from './App';

// kick off the polyfill!
smoothscroll.polyfill();
promiseFinally.shim();


const settings = qs.parse(window.location.search.substring(1));
let parentSettings = {}
//const parentSettings = qs.parse(window.parent.location.search.substring(1));
const referrerSearch = document.referrer.split('?')
console.log(referrerSearch);
if(referrerSearch.length > 1){
  const rawParentSettings = qs.parse(referrerSearch[1])
  const parentKeys = Object.keys(rawParentSettings)
  for(let key of parentKeys){
    const newKey = key.replace(/[^0-9a-z]/gi, '')
    parentSettings[newKey] = rawParentSettings[key]
  }
  console.log(parentSettings)
  const { fbclid, adCode } = parentSettings;
  if (fbclid) {
    if (!adCode) {
      parentSettings.adSource = 'facebook'
      parentSettings.adCode = "1900496376913132";
    }else{
      parentSettings.adSource = "facebook";
    }
  } 
}

if (!('backgroundColor' in settings)) {
 // settings.backgroundColor = 'rgba(4, 110, 209, 1)';
}
if (!('buttonColor' in settings)) {
  settings.buttonColor = 'rgba(4, 110, 209, 1)';
}

if (!('color' in settings)) {
  settings.color = 'rgba(255,255,255,1)';
}

console.log(settings)


const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: { main: settings.color },
    text: {
      default: settings.color,
    },
    textColor: settings.color,
  },
  background: {
    default: 'rgba(4,110,209,1)',
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightMedium: 500,
    caption: {
      color: settings.color,
    },
    title: {
      fontSize: 20,
    },
    body2: {
      color: settings.color,
    },
    body1: {
      fontWeight: 500,
    },
    subheading: {
      fontSize: 12,
    },
    button: {
      fontStyle: 'italic',
    },
  },
  overrides: {
    MuiTypography: {
      colorPrimary: settings.color,
    },
    MuiInput: {
      root: {
        color: settings.color,
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid ${settings.color}`,
        },
        '&$focused:after': {
          borderBottomColor: settings.color,
        },
        '&$error:after': {
          borderBottomColor: settings.color,
        },
        '&:before': {
          borderBottom: `1px solid ${settings.color}`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid ${settings.color}`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted ${settings.color}`,
        },
      },
    },
    MuiSelect: {
      root: {
        color: settings.color,
      },
    },
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        background: `linear-gradient(45deg, ${settings.buttonColor} 30%, ${settings.buttonColor} 90%)`,
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
    },
  },
});


ReactDOM.render((
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App settings={settings} parentSettings={parentSettings}/>
      </MuiThemeProvider>),
document.getElementById('root'));



/*import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();*/
