import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  table: {
    width: '100%',
  },
  tableCellRight: {
    padding: 0,
    border: 0,
    verticalAlign: 'bottom',
   // textAlign: 'center', //var
  },
  tableCellLeft: {
    width: 40,
    padding: 0,
    border: 0,
    verticalAlign: 'sub',
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  hightLight: {
    fontWeight: 'bold',
    fontFamily: theme.typography.title.fontFamily,
    fontSize: theme.typography.title.fontSize,
  },
  itemNumber: {
    color: theme.typography.caption.color,
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: theme.typography.body2.fontSize,
  },
});

function Label(props) {
  const {
    classes, color, label, itemNumber, showItemNumber, questionTopPadding
  } = props;


  // var extract = str.match(/{(.*)}/).pop();
  const makeLabelString = () => {
    const labelArray = label.split('<b>');
    let even = false;
    let key = 0;

    const formattedLabelArray = labelArray.map((piece) => {
      even = !even;
      key += 1;
      return even ? (
        <span key={key}>
          {piece}
        </span>
      ) : (
        <span key={key} className={classes.hightLight}>
          {piece}
        </span>
      );
    });

    key += 1;

    /* formattedLabelArray.unshift(
      <span key={key} className={classes.itemNumber}>
      {`${sequence} →`}
      </span>
    ) */

    // const formattedLabel = formattedLabelArray.join()

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableBody>

            <TableRow className={classes.table}>
              {showItemNumber ? <TableCell className={classes.tableCellLeft}>
                <span key={key} className={classes.itemNumber}>
                  {`${itemNumber} →`}
                </span>
              </TableCell> : null }
              <TableCell className={classes.tableCellRight}>
                <Typography
                  className={classes.title}
                  style={{ color }}
                  variant="h6"
                  gutterBottom
                >
                  {formattedLabelArray}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };


  return makeLabelString();
}

Label.propTypes = {
  classes: PropTypes.object.isRequired,
};

Label.defaultProps = {
  showItemNumber: false,
};


export default withStyles(styles)(Label);

