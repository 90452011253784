module.exports = {
  language:{
    terms: {'Continuar':'Continue'},
    individual:{'Individual':'Individual', 'Familia':'Family'},
    ageRange: {'Menor de 18 años':'Younger than 18', 'Mayores de 65': '65 and Older'},
    condition: {'NINGUNO':'NONE', 'Derrame cerebral':'Stroke', 'Cáncer':'Cancer', 'Diabetes': 'Diabetes', 'SIDA/VIH':'AIDS/HIV', 'Ataque al corazón':'Heart Attack', 'Enfermedad pulmonar':'Pulmonary Disease'},
  },
  english:{
    individual: {
      fieldType: 'option',
      format: 'text',
      title: 'Select if you are looking for an individual or family plan',
      label: 'Are you looking for an <b>Individual<b> or <b>Family plan?<b>*',
      options: ['Individual', 'Family'],
      shortText: 'Are you an Individual?',
      attribute: 'individual',
      errorMessage: 'Please complete this field',
    },
    region: {
      fieldType: 'choice',
      title: 'Select the State you currently live in',
      format: 'text',
      label: 'Select your <b>State<b>*',
      choices: ['AL',	'AZ',	'AR',	'CA',	'CO',	'CT',	'DE',	'FL',	'GA',	'ID',	'IL',	'IN',	'IA',	'KS',	'KY',	'LA',	'ME',	'MD',	'MA',	'MI',	'MN',	'MS',	'MO',	'MT',	'NE',	'NV',	'NH',	'NJ',	'NM',	'NY',	'NC',	'ND',	'OH',	'OK',	'OR',	'PA',	'RI',	'SC',	'SD',	'TN',	'TX',	'UT',	'VT',	'VA',	'WA',	'WV',	'WI',	'WY'],
      shortText: 'State',
      attribute: 'region',
      errorMessage: 'Please select a state',
    },
    postalCode: {
      fieldType: 'textfield',
      title: 'Provide your 5-digit Zip Code',
      type: 'tel',
      format: 'postal',
      label: 'Enter Your <b>Zip Code<b>*',
      shortText: 'Zip Code',
      attribute: 'postalCode',
      errorMessage: 'Please provide your zip code',
    },
    household: {
      fieldType: 'range',
      title: 'Select the total number of permanent residents in your house',
      format: 'integer',
      label: 'Select your <b>Household Size<b>*',
      highest: 8,
      shortText: 'Household Size',
      attribute: 'household',
      clearOnChange: 'income',
      errorMessage: 'Please select one of the above',
    },
    income: {
      fieldType: 'option',
      title: 'Select the combined amount of annual income for all permanent residents in your house',
      format: 'text',
      label: 'What is your expected Total Household Income for <b>2022?<b>*',
      conditionalField: 'household',
      shortText: 'Income',
      attribute: 'income',
      errorMessage: 'Please make a selection',
      conditionalOptions: {
        '1': [
          'Above $48,000',
          '$36,000 - $47,999',
          '$24,000 - $35,999',
          '$12,000 - $23,999',
          'Below $12,000',
        ],
        '2': [
          'Above $64,000',
          '$48,000 - $63,999',
          '$32,000 - $47,999',
          '$16,000 - $31,999',
          'Below $16,000',
        ],
        '3': [
          'Above $81,000',
          '$61,000 - $80,999',
          '$40,000 - $60,999',
          '$20,000 - $39,999',
          'Below $20,000',
        ],
        '4': [
          'Above $98,000',
          '$73,000 - $97,999',
          '$49,000 - $72,999',
          '$24,000 - $48,999',
          'Below $24,000',
        ],
        '5': [
          'Above $115,000',
          '$86,000 - $114,999',
          '$56,000 - $85,999',
          '$28,000 - $55,999',
          'Below $28,000',
        ],
       '6': [
          'Above $131,000',
          '$98,000 - $130,999',
          '$65,000 - $97,999',
          '$32,000 - $64,999',
          'Below $32,000',
        ],
        '7': [
          'Above $148,000',
          '$111,000 - $147,999',
          '$74,000 - $110,999',
          '$37,000 - $73,999',
          'Below $37,000',
        ],
        '8': [
          'Above $165,000',
          '$123,000 - $164,999',
          '$82,000 - $122,999',
          '$41,000 - $81,999',
          'Below $41,000',
        ],
      }
    },
    networth: {
      fieldType: 'option',
      title: 'Select your total Net Worth?',
      format: 'text',
      label: 'What is your total <b>Net Worth<b>*?',
      ifConditionalFields:['income'],
      ifThenOptions: {income:[
        'Below $12,000','$12,000 - $23,999','$24,000 - $35,999','Below $16,000','$16,000 - $31,999','$32,000 - $47,999','Below $20,000', '$20,000 - $39,999',  '$40,000 - $60,999','Below $24,000','$24,000 - $48,999', '$49,000 - $72,999','Below $28,000', '$28,000 - $55,999','$56,000 - $85,999','Below $32,000','$32,000 - $64,999','$65,000 - $97,999', 'Below $37,000', '$37,000 - $73,999', '$74,000 - $110,999','Below $41,000','$41,000 - $81,999', '$82,000 - $122,999',
      ]},
      conditionalField: 'household',
      shortText: 'Networth',
      attribute: 'networth',
      errorMessage: 'Please make a selection',
      conditionalOptions: {
        '1': [
          'Above $150,000',
          '$100,000 - $149,999',
          '$50,000 - $99,999',
          '$35,000 - $49,999',
          'Below $35,000',
        ],
        '2': [
          'Above $200,000',
          '$150,000 - $199,999',
          '$85,000 - $149,999',
          '$50,000 - $84,999',
          'Below $50,000',
        ],
        '3': [
          'Above $250,000',
          '$175,000 - $249,999',
          '$100,000 - $174,999',
          '$65,000 - $99,999',
          'Below $65,000',
        ],
        '4': [
          'Above $300,000',
          '$225,000 - $299,999',
          '$125,000 - $224,999',
          '$75,000 - $124,999',
          'Below $75,000',
        ],
      '5': [
          'Above $350,000',
          '$250,000 - $349,999',
          '$150,000 - $249,999',
          '$85,000 - $149,999',
          'Below $85,000',
        ],
       '6': [
          'Above $400,000',
          '$275,000 - $399,999',
          '$175,000 - $274,999',
          '$100,000 - $174,999',
          'Below $100,000',
        ],
        '7': [
          'Above $450,000',
          '$300,000 - $449,999',
          '$200,000 - $299,999',
          '$125,000 - $199,999',
          'Below $125,000',
        ],
        '8': [
          'Above $500,000',
          '$350,000 - $499,999',
          '$250,000 - $349,999',
          '$150,000 - $249,999',
          'Below $150,000',
        ],
      }
    },
    pregnant: {
      fieldType: 'option',
      title: 'Anyone in your household currently pregnant?',
      format: 'text',
      label: 'Anyone on this policy <b>Pregnant?<b>*',
      options: ['No', 'Yes'],
      shortText: 'Pregnant',
      attribute: 'pregnant',
      errorMessage: 'Please make a selection',
    },
    condition: {
      fieldType: 'option',
      title: 'Select NONE or choose the most recent diagnosis if you have multiple pre-existing conditions',
      format: 'text',
      label: 'Any of these within <b>Last 5 Years?<b>*',
      options: ['NONE', 'Stroke', 'Cancer', 'Diabetes', 'AIDS/HIV', 'Heart Attack', 'Pulmonary Disease'],
      shortText: 'Condition',
      attribute: 'condition',
      errorMessage: 'Please make a selection',
    },
    /*splitPolicy: {
      fieldType: 'option',
      title: 'Interested in saving more money with a split policy?',
      format: 'text',
      ifConditionalFields:['household', 'condition'],
      ifThenOptions: {household:[2,3,4,5,6,7,8], condition:['Stroke', 'Cancer', 'Diabetes', 'AIDS/HIV', 'Heart Attack', 'Pulmonary Disease']},
      label: 'Interested in saving more money with a split policy?',
      options: ['Yes', 'No'],
      shortText: 'splitPolicy',
      attribute: 'splitPolicy',
      errorMessage: 'Please make a selection',
    },*/
    ageRange: {
      fieldType: 'option',
      title: 'Select your age group',
      format: 'text',
      label: 'Select your <b>Age Range<b>*',
      options: ['Younger than 18', '18 - 26', '27 - 38', '39 - 50', '51 - 64', '65 and Older'],
      shortText: 'Age Range',
      attribute: 'ageRange',
      errorMessage: 'Please make a selection',
    },
    medicareInterest: {
      fieldType: 'option',
      title: 'Select if you are interested in Medicare?',
      format: 'text',
      ifConditionalFields:['ageRange'],
      ifThenOptions: {ageRange:['65 and Older']},
      label: 'Are you interested in <b>Medicare?<b>*',
      options: ['Yes', 'No'],
      shortText: 'medicareInterest',
      attribute: 'medicareInterest',
      errorMessage: 'Please make a selection',
    },
    firstName: {
      fieldType: 'textfield',
      title: 'Type your First Name below',
      type: 'text',
      format: 'text',
      label: 'Enter Your <b>First Name<b>*',
      shortText: 'First Name',
      attribute: 'firstName',
      errorMessage: 'Please make a selection',
    },
    lastName: {
      fieldType: 'textfield',
      title: 'Type your Last Name below',
      type: 'text',
      format: 'text',
      label: 'Enter Your <b>Last Name<b>*',
      shortText: 'Last Name',
      attribute: 'lastName',
      errorMessage: 'Please make a selection',
    },
    email: {
      fieldType: 'textfield',
      title: 'Type your Email below',
      type: 'email',
      format: 'email',
      label: 'Enter <b>Your Email<b>*',
      shortText: 'Email',
      attribute: 'email',
      errorMessage: 'Please make a selection',
      placeholder: 'your-email@domain.com',
    },
    phone: {
      fieldType: 'textfield',
      title: 'Provide your 10-digit phone number so we can match you with your Exclusive Health Quote',
      type: 'tel',
      format: 'phone',
      label: 'Enter <b>Your Phone<b>*',
      shortText: 'Phone',
      attribute: 'phone',
      errorMessage: 'Please enter a valid phone',
      placeholder: '(111) 111-1111',
    },
    terms: {
      fieldType: 'option',
      triggerTCPACert: true,
      format: 'text',
      tiitle: 'Further information about our terms and conditions can be found at the bottom of our website',
      label: 'By pressing "<b>Continue<b>" you agree to our Terms & Conditions and would like to see your new health insurance quote*',
      options: ['Continue'],
      attribute: 'terms',
      shortText: 'Terms & Conditions',
      errorMessage: 'Please make a selection',
      link: {
        label: 'terms & conditions',
        url: 'https://shopinsuranceplans.com/terms-conditions/',
      },
    }
  },
  spanish:{
    individual: {
      fieldType: 'option',
      format: 'text',
      title: 'Seleccione si está buscando un plan individual o familiar.',
      label: '¿Estás buscando un plan <b>Individual<b> o <b>Familiar?<b>*',
      options: ['Individual', 'Familia'],
      shortText: '¿Eres un individuo?',
      attribute: 'individual',
      errorMessage: 'Por favor complete este campo',
    },
    region: {
      fieldType: 'choice',
      title: 'Seleccione el estado en el que vive actualmente',
      format: 'text',
      label: 'Seleccione su <b>Estado<b>*',
      choices: ['AL',	'AZ',	'AR',	'CA',	'CO',	'CT',	'DE',	'FL',	'GA',	'ID',	'IL',	'IN',	'IA',	'KS',	'KY',	'LA',	'ME',	'MD',	'MA',	'MI',	'MN',	'MS',	'MO',	'MT',	'NE',	'NV',	'NH',	'NJ',	'NM',	'NY',	'NC',	'ND',	'OH',	'OK',	'OR',	'PA',	'RI',	'SC',	'SD',	'TN',	'TX',	'UT',	'VT',	'VA',	'WA',	'WV',	'WI',	'WY'],
      shortText: 'State',
      attribute: 'region',
      errorMessage: 'Por favor seleccione un estado',
    },
    postalCode: {
      fieldType: 'textfield',
      title: 'Proporcione su código postal de 5 dígitos',
      type: 'tel',
      format: 'postal',
      label: 'Ingrese su <b>Código Postal<b>*',
      shortText: 'Zip Code',
      attribute: 'postalCode',
      errorMessage: 'Por favor ingrese su código postal',
    },
    household: {
      fieldType: 'range',
      title: 'Seleccione el número total de residentes permanentes en su casa',
      format: 'integer',
      label: 'Seleccione el <b>Tamaño de su Hogar<b>*',
      highest: 8,
      shortText: 'Household Size',
      attribute: 'household',
      clearOnChange: 'income',
      errorMessage: 'Por favor seleccione uno de los anteriores',
    },
    income: {
      fieldType: 'option',
      title: 'Seleccione la cantidad combinada de ingresos anuales para todos los residentes permanentes en su casa',
      format: 'text',
      label: '¿Cuál es su ingreso familiar total esperado para <b>2022?<b>*',
      conditionalField: 'household',
      shortText: 'Income',
      attribute: 'income',
      errorMessage: 'Por favor haz una selección',
      conditionalOptions: {
        '1': [
          'Encima $48,000',
          '$36,000 - $47,999',
          '$24,000 - $35,999',
          '$12,000 - $23,999',
          'Abajo $12,000',
        ],
        '2': [
          'Encima $64,000',
          '$48,000 - $63,999',
          '$32,000 - $47,999',
          '$16,000 - $31,999',
          'Abajo $16,000',
        ],
        '3': [
          'Encima $81,000',
          '$61,000 - $80,999',
          '$40,000 - $60,999',
          '$20,000 - $39,999',
          'Abajo $20,000',
        ],
        '4': [
          'Encima $98,000',
          '$73,000 - $97,999',
          '$49,000 - $72,999',
          '$24,000 - $48,999',
          'Abajo $24,000',
        ],
      '5': [
          'Encima $115,000',
          '$86,000 - $114,999',
          '$56,000 - $85,999',
          '$28,000 - $55,999',
          'Abajo $28,000',
        ],
      '6': [
          'Encima $131,000',
          '$98,000 - $130,999',
          '$65,000 - $97,999',
          '$32,000 - $64,999',
          'Abajo $32,000',
        ],
        '7': [
          'Encima $148,000',
          '$111,000 - $147,999',
          '$74,000 - $110,999',
          '$37,000 - $73,999',
          'Abajo $37,000',
        ],
        '8': [
          'Encima $165,000',
          '$123,000 - $164,999',
          '$82,000 - $122,999',
          '$41,000 - $81,999',
          'Abajo $41,000',
        ],
      }
    },
    pregnant: {
      fieldType: 'option',
      title: '¿Alguien en tu casa está embarazada actualmente?',
      format: 'text',
      label: '¿Alguien esta <b>Embarazada?<b>*',
      options: ['No', 'Yes'],
      shortText: 'Pregnant',
      attribute: 'pregnant',
      errorMessage: 'Por favor haz una selección',
    },
    condition: {
      fieldType: 'option',
      title: 'Seleccione NINGUNO o elija el diagnóstico más reciente si tiene varias afecciones preexistentes',
      format: 'text',
      label: '¿Alguno de estos en los <b>últimos 5 años?<b>*',
      options: ['NINGUNO', 'Derrame cerebral', 'Cáncer', 'Diabetes', 'SIDA/VIH', 'Ataque al corazón', 'Enfermedad pulmonar'],
      shortText: 'Condition',
      attribute: 'condition',
      errorMessage: 'Por favor haz una selección',
    },
    /*splitPolicy: {
      fieldType: 'option',
      title: 'Interested in saving more money with a split policy?',
      format: 'text',
      ifConditionalFields:['household', 'condition'],
      ifThenOptions: {household:[2,3,4,5,6,7,8], condition:['Stroke', 'Cancer', 'Diabetes', 'AIDS/HIV', 'Heart Attack', 'Pulmonary Disease']},
      label: 'Interested in saving more money with a split policy?',
      options: ['Yes', 'No'],
      shortText: 'splitPolicy',
      attribute: 'splitPolicy',
      errorMessage: 'Please make a selection',
    },*/
    ageRange: {
      fieldType: 'option',
      title: 'Seleccione su grupo de edad',
      format: 'text',
      label: 'Seleccione su <b>Rango de Edad<b>*',
      options: ['Menor de 18 años', '18 - 26', '27 - 38', '39 - 50', '51 - 64', 'Mayores de 65'],
      shortText: 'Age Range',
      attribute: 'ageRange',
      errorMessage: 'Por favor haz una selección',
    },
    firstName: {
      fieldType: 'textfield',
      title: 'Escriba su nombre abajo',
      type: 'text',
      format: 'text',
      label: 'Ingrese su <b>Nombre<b>*',
      shortText: 'First Name',
      attribute: 'firstName',
      errorMessage: 'Por favor complete el campo',
    },
    lastName: {
      fieldType: 'textfield',
      title: 'Escriba su apellido debajo',
      type: 'text',
      format: 'text',
      label: 'Ingrese su <b>Apellido<b>*',
      shortText: 'Last Name',
      attribute: 'lastName',
      errorMessage: 'Por favor complete el campo',
    },
    email: {
      fieldType: 'textfield',
      title: 'Escriba su correo electrónico a continuación',
      type: 'email',
      format: 'email',
      label: 'Ingrese su <b>Correo Electrónico<b>*',
      shortText: 'Email',
      attribute: 'email',
      errorMessage: 'Por favor complete el campo',
      placeholder: 'su-mail@dominio.com',
    },
    phone: {
      fieldType: 'textfield',
      title: 'Proporcione su número de teléfono de 10 dígitos para que podamos asociarlo con su Cotización de salud exclusiva',
      type: 'tel',
      format: 'phone',
      label: ' Ingrese su <b>Teléfono<b>*',
      shortText: 'Phone',
      attribute: 'phone',
      errorMessage: 'Por favor complete el campo',
      placeholder: '(111) 111-1111',
    },
    terms: {
      fieldType: 'option',
      format: 'text',
      tiitle: 'Puede encontrar más información sobre nuestros términos y condiciones en la parte inferior de nuestro sitio web.',
      label: 'Al presionar <b>Continuar<b>, usted acepta nuestros Términos y condiciones y le gustaría ver su nueva cotización de seguro de salud.*',
      options: ['Continuar'],
      attribute: 'terms',
      shortText: 'Terms & Conditions',
      errorMessage: 'Por favor haz una selección',
      link: {
        label: 'terms & conditions',
        url: 'https://shopinsuranceplans.com/terms-conditions/',
      },
    }
}};
