import React, { Component } from 'react';
import ReactPixel from 'react-facebook-pixel';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import Iframe from 'react-iframe';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import { isMobile } from 'react-device-detect';
import { enableBodyScroll } from 'body-scroll-lock';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  messageText: {
  //  marginTop: theme.spacing.unit,
    padding: theme.spacing.unit,
    verticalAlign: 'middle',
  },
  facebook: {
    textAlign: 'center',
  },
  rocket: {
    height: '30%',
  },
  progress: {
    color: green,
  },
  buttonTextOverride: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: theme.typography.h6.fontFamily,
    fontSize: theme.typography.h6.fontSize,
  },
  buttonCssRoot: {
    backgroundColor: 'rgba(255,0, 0, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(255,0, 0, 0.1)',
    },
  },
});




class Complete extends Component {
  timer = null;

  state = {
    completed: 0,
    cscompleted: 0,
    crossSellSent: false,
    crossSellProcessing: false,
  };

  componentDidMount() {
    const { tracking } = this.props;
    if (isMobile) {
      enableBodyScroll(window);
    }
    this.fireTracking(tracking);
    this.timer = setInterval(this.progress, 20);
  }
  determineResult = (result) => {
    const { condition, povertyLevel, household } = result;
    //console.log('determineResult')
    //console.log(household)
    // console.log(povertyLevel)
    // console.log(condition)
    if (condition === "NONE") {
      if (
        povertyLevel === 3 ||
        povertyLevel === 4 ||
        povertyLevel === 2
      ) {
        return true;
      }
    } else if (
      (povertyLevel === 4 && household > 1) ||
      (povertyLevel === 3 && household > 1)
    ) {
      return false;
    }

    return false;
  };
  fireStaticTracking = (tracking) => {
    return;
    /*const { campaign } = tracking
    const result = this.determineResult(tracking)

    const options = {
      autoConfig: true, 	// set pixel's autoConfig
      debug: false, 		// enable logs
    };
    const action = result ? 'SubmitApplication' : 'Lead' 
 
    ReactPixel.init('1900496376913132', options);
    ReactPixel.track(action, { campaign: campaign ? campaign : 'none' });*/
  };
  fireTracking = (tracking = {}) => {
    //console.log(tracking)

    const {
      campaign,
      adCode,
      adSource,
      condition,
      povertyLevel,
      os,
    } = tracking;

    if (!adCode) {
      return this.fireStaticTracking(tracking);
    }

    const result = this.determineResult(tracking);
    switch (adSource) {
      case "facebook":
        const options = {
          autoConfig: true, // set pixel's autoConfig
          debug: false, // enable logs
        };
        const action = result ? "SubmitApplication" : "Lead";
        if (os === "Android" && action === "Lead") {
          return;
        }

        ReactPixel.init(adCode, options);
        ReactPixel.track(action);

        break;
      case "google":
        const { referrer } = document;
        if (referrer) {
          window.parent.postMessage(
            JSON.stringify({
              event: "newLead",
            }),
            `${referrer}`
          );
        }
        break;
      case "instagram":
        break;
      case "twitter":
        break;
      case "reddit":
        break;
      default:
        console.error("error tracking");
        break;
    }
  };

  sendCrossSell = async (contact) => {
    const {
      firstName,
      lastName,
      postalCode,
      region: state,
      household,
      condition,
      income,
      ageRange,
      email,
      phone: primaryPhone,
      contactIP,
      tcpaCertLink,
      povertyLevel,
    } = contact;
    this.setState({ crossSellProcessing: true });
    const url = process.env.REACT_APP_CROSS_SELL_URL;

    const params = new URLSearchParams({
      firstName,
      lastName,
      postalCode,
      state,
      household,
      condition,
      income,
      ageRange,
      email,
      primaryPhone,
      contactIP,
      tcpaCertLink,
      povertyLevel,
    });

    console.log(params.toString());

    try {
      this.timer = setInterval(this.csprogress, 20);
      await fetch(`${url}/?${params.toString()}`);
      console.log('DONEEEEE');
      this.setState({ crossSellProcessing: false, crossSellSent: true });
    } catch (err) {
      //send error notification
      console.log("ERRROR");
      console.error(err);
      this.setState({ crossSellProcessing: false, crossSellSent: false });
    }
  };

  progress = () => {
    const { completed } = this.state;
    if (completed < 99) {
      this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
    }
  };

  csprogress = () => {
    const { cscompleted } = this.state;
    if (cscompleted < 99) {
      this.setState({
        cscompleted: cscompleted >= 100 ? 0 : cscompleted + 1,
      });
    }
  };

  makeLifeCrossSellButton = (formData) => {
        const {
          crossSellProcessing,
          crossSellSent,
          cscompleted,
        } = this.state;
    const { classes, isSpanish } = this.props;

    console.log(`crossSellProcessing: ${crossSellProcessing}`);
    console.log(`crossSellSent: ${crossSellSent}`);

    if (crossSellProcessing) {
      return (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Typography
            variant="h6"
            color="primary"
            className={classes.messageText}
          >
            Processing your request...
          </Typography>
          <CircularProgress
            variant="determinate"
            size={50}
            value={cscompleted}
          />
        </div>
      );
    }else if (crossSellSent) {
      return (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Typography
            variant="h6"
            color="primary"
            className={classes.messageText}
          >
            Success! There will also be one exclusive life insurance agent
            contacting you shortly to go over your options!{" "}
          </Typography>
          <CheckIcon
            className={classes.icon}
            color="primary"
            style={{ fontSize: 60 }}
            component={(svgProps) => (
              <svg {...svgProps}>
                <defs>
                  <linearGradient id="gradient1">
                    {/* <stop offset="1%" stopColor={blue[400]} /> */}
                    <stop offset="100%" stopColor={green[400]} />
                  </linearGradient>
                </defs>
                {React.cloneElement(svgProps.children[0], {
                  fill: "url(#gradient1)",
                })}
              </svg>
            )}
          />
        </div>
      );
    }
    return (
      <div>
        <Typography
          style={{ marginTop: 20, marginBottom: 20 }}
          variant="h6"
          color="primary"
          className={classes.messageText}
        >
          {isSpanish === true
            ? `¿Está interesado en ver también sus opciones de seguro de vida?`
            : `Are you interested in seeing your Life Insurance options too?`}
        </Typography>

        <Button
          style={{ marginBottom: 40 }}
          onClick={() => this.sendCrossSell(formData)}
          color="inherit"
          variant="contained"
          classes={{
            root: classes.buttonTextOverride,
          }}
        >
          {isSpanish === true ? "¡Si! Estoy interesado" : `Yes! I'm Interested.`}
        </Button>
      </div>
    );
  };

  makePhoneSellButton = () => {
    const { classes } = this.props;
    return (
      <div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            //  style={{ marginTop: 5, marginBottom: 5 }}
            variant="h6"
            color="primary"
            className={classes.messageText}
          >
            Speak To A Live Agent!
          </Typography>
          <Button
            //style={{ marginBottom: 40 }}
            onClick={() => window.open(`tel:8886763225`)}
            color="inherit"
            variant="contained"
            classes={{
              root: classes.buttonTextOverride,
            }}
          >
            Click Here to Call
          </Button>
          <Typography
            //  style={{ marginTop: 5, marginBottom: 5 }}
            variant="h6"
            color="primary"
            className={classes.messageText}
          >
            (888) 676-3225
          </Typography>
        </Grid>
      </div>
    );
  };

  render() {
    const { completed } = this.state;
    const { classes, backgroundColor, isSpanish, tracking = {} } = this.props;
    const { povertyLevel, formData, region } = tracking;
    const showLifeButtonLevels = [
      "2",
      "3",
      "4",
      "7",
      "8",
      "9",
      2,
      3,
      4,
      7,
      8,
      9,
    ];

    const hidePhoneSellRegions = [
      "MA",
    ];


    let resultMessage = "";
    if (isSpanish === true) {
      resultMessage =
        completed > 98
          ? "Su información fue enviada con éxito!"
          : "Procesando su presentación....";
    } else {
      resultMessage =
        completed > 98
          ? "You will receive your new quote via phone or email from ONE licensed agent!"
          : "Processing your submission....";
    }

    return (
      <div
        style={{
          zIndex: 9999999,
          backgroundColor: backgroundColor || "transparent",
          width: "100%",
          height: "100%",
          position: "fixed",
          textAlign: "center",
          overflow: "scroll",
        }}
      >
        <Typography
          variant="h4"
          color="primary"
          className={classes.messageText}
        >
          {isSpanish === true ? "Felicidades!" : "Congratulations!"}
        </Typography>
        <Typography
          variant="h6"
          color="primary"
          className={classes.messageText}
        >
          {resultMessage}
        </Typography>
        <div className={classes.wrapper}>
          {completed > 98 ? (
            <CheckIcon
              className={classes.icon}
              color="primary"
              style={{ fontSize: 60 }}
              component={(svgProps) => (
                <svg {...svgProps}>
                  <defs>
                    <linearGradient id="gradient1">
                      {/* <stop offset="1%" stopColor={blue[400]} /> */}
                      <stop offset="100%" stopColor={green[400]} />
                    </linearGradient>
                  </defs>
                  {React.cloneElement(svgProps.children[0], {
                    fill: "url(#gradient1)",
                  })}
                </svg>
              )}
            />
          ) : (
            <CircularProgress
              variant="determinate"
              size={50}
              value={completed}
            />
          )}
        </div>

        {hidePhoneSellRegions.includes(region) ? null : this.makePhoneSellButton({
          povertyLevel,
          ...formData,
        })}

        {showLifeButtonLevels.includes(povertyLevel)
          ? this.makeLifeCrossSellButton({
              povertyLevel,
              ...formData,
            })
          : null}
        <div className={classes.facebook}>
          <Iframe
            url="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.facebook.com%2Fnewhealthplans%2F&layout=button_count&size=small&mobile_iframe=true&width=0&height=0&appId"
            width="100"
            height="75"
            position="inline"
            style={{ border: "none", overflow: "hidden" }}
            id="myId"
            // className="myClassname"
            display="initial"
          />
        </div>
      </div>
    );
  }
}

// scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media

export default withStyles(styles)(Complete);

Complete.propTypes = {
  open: PropTypes.bool,

};

Complete.defaultProps = {
  open: true,
};
