import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
    message: {
        backgroundColor: 'rgba(255,0, 0, 1)',
        maxWidth: 300,
        marginLeft: 25,
    },
    messageText: {
        paddingLeft: theme.spacing.unit,
        verticalAlign: 'middle',
    },
});

const ErrorMessage = ({ ...props }) => {
  const { classes, message } = props;

  return (
    <Paper className={classes.message}>
    <Typography variant="body2" className={classes.messageText}>
      {message}
    </Typography>
  </Paper>
  );
};


export default withStyles(styles)(ErrorMessage);

ErrorMessage.propTypes = {
  open: PropTypes.bool,
  height: PropTypes.number,
  shadow: PropTypes.number,
  incomplete: PropTypes.array,
};

ErrorMessage.defaultProps = {
  open: true,
  height: 50,
  incomplete: [],
  shadow: 0,
};