import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing.unit * 0.5,
    maxWidth: 300,
    backgroundColor: 'transparent',
  },
  title: {
    cursor: 'pointer',
  },
  grid: {
    alignItems: 'center',
    direction: 'row',
    justify: 'space-between',
  },
  optionMobile: {
    backgroundColor: 'rgba(255,255,255,0.25)',
    margin: theme.spacing.unit,
    padding: theme.spacing.unit * 0.25,
    borderRadius: 4,
    borderWidth: theme.spacing.unit * 0.15,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',
  },
  option: {
    backgroundColor: 'rgba(255,255,255,0.25)',
    margin: theme.spacing.unit,
    padding: theme.spacing.unit * 0.25,
    borderRadius: 4,
    borderWidth: theme.spacing.unit * 0.15,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.50)',
    },
  },
  selectedOption: {
    backgroundColor: 'rgba(255,255,255,0.25)',
    margin: theme.spacing.unit,
    padding: theme.spacing.unit * 0.5,
    borderRadius: 4,
    borderWidth: theme.spacing.unit * 0.50,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',

  },
  wrapper: {
   // margin: theme.spacing.unit,
    position: 'relative',
  },
  fabProgress: {
    color: '#FFFFFF',
    position: 'absolute',
    zIndex: 1,
  },

});

class OptionsLayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecting: '',
      disabled: false,
    };
  }

  focus = () => {
      this.setState({disabled: false})
  }

  blur = () => {
      this.setState({disabled: true})
  }

    handleUpdate = (field, newValue) => {

      const { disabled } = this.state;
      const { value } = this.props;

      if (disabled){
        return
     }

      if (value === newValue) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.props.dismiss();
          this.props.moveNext(1);
        }, 275);
        return;
      }

      this.props.handleUpdate(field, newValue);

      this.setState({ selecting: newValue },
        () => {
          this.timer = setTimeout(() => {
            this.setState({ selected: newValue, selecting: '' }, () => {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => {
                this.props.dismiss();
                this.props.moveNext(1);
              }, 275);
            });
          }, 275);
        });
    }

    makeOptions = () => {
      const { selecting } = this.state;
      const {
        options, color, attribute, classes, value, conditionalValue
      } = this.props;

      return options.map((option) => {
        const loading = selecting === option;
        const success = value === option;

        return (
          <div
            style={{ color }}
            key={`${attribute}${option}`}
            className={loading || success ? classes.selectedOption : isMobile ? classes.optionMobile : classes.option}
            onClick={() => this.handleUpdate(attribute, option)}
          >
            <Grid container className={classes.root}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={16}
                  className={classes.grid}
                  alignItems="center"
                  direction="row"
                  justify="space-between"
                >
                  <Grid item>
                    <Typography
                      className={classes.title}
                      style={{ color }}
                      variant="h6"
                    >
                      {option}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <div className={classes.wrapper}>
                      {success && !loading ? <CheckIcon fontSize="inherit" color="primary" /> : null}
                      {loading && <CircularProgress color="primary" size={25} />}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      });
    }

    render() {
      const { classes } = this.props;

      return (
        <div className={classes.root}>
          {this.makeOptions()}
        </div>
      );
    }
}


OptionsLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  moveNext: PropTypes.func,
  dismiss: PropTypes.func, // for when being used as an embed like a choice component. should look at doing choice as HOC
  conditionalValue: PropTypes.string,
};

OptionsLayer.defaultProps = {
  moveNext: () => {},
  dismiss: () => {},
  conditionalValue: '',
};

export default withStyles(styles)(OptionsLayer);

// make option highlight bordrrs on selected
// change check color to white
