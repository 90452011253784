import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { addWheelListener, removeWheelListener } from 'wheel';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ErrorMessage from './ErrorMessage';
import Label from './fields/Label';
import Option from './fields/Option';
import Choice from './fields/Choice';
import Range from './fields/Range';
import Text from './fields/Text';


const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'inline',
    backgroundColor: 'transparent',
  },
  page: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    width: '100%',
  },
  container: {
    width: '100%',
  },
  grid: {
    width: '80%',
  },
  fieldGrid: {
    marginLeft: theme.spacing.unit * 1,
    width: '100%',
  },
  errorGrid: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 1,
    maxWidth: '300',
  },
});


class BrowserForm extends Component {
  constructor(props) {
    super(props);
    this.wrapperRefs = [];
    this.activeIndex = 0;
    this.lastScrollTop = 0;
    this.state = {
      loaded: false,
      //topOffset: 0,
    };
  }

  componentWillMount() {
    document.body.style.overflowY = 'auto';
  }

  componentDidMount() {
    const { clickOutside } = this.props;
    this.thisOne = ReactDOM.findDOMNode(this);
    this.thisOne.addEventListener('scroll', this.handleScroll);
    this.thisOne.addEventListener('mousedown', clickOutside);
    addWheelListener(document, this.onWheel);
    document.addEventListener('keydown', this.onKeydown);
  }

  componentDidUpdate() {
    const { loaded } = this.state;
    if (!loaded) {

      /*const first = this.wrapperRefs[0];
      const firstRec = first.current.getBoundingClientRect();
      const { height } = this.props;
      const rectHeight = firstRec.height;
      const topOffset = ((height / 2) - (rectHeight));
      this.setState({ loaded: true, topOffset });*/


    }
  }

  componentWillUnmount() {
    const { clickOutside } = this.props;
    this.thisOne.removeEventListener('scroll', this.handleScroll);
    this.thisOne.removeEventListener('mousedown', clickOutside);
    removeWheelListener(document, this.onWheel);
    document.removeEventListener('keydown', this.onKeydown);
    document.body.style.overflowY = 'hidden';
  }


  onKeydown = (event) => {
    const { key } = event;
    const { clickOutside } = this.props;
    if (key === 'ArrowUp') {
      clickOutside();
      this.moveNext(-1);
      event.preventDefault();
    } else if (key === 'ArrowDown') {
      this.moveNext(1);
      event.preventDefault();
    } else if (key === 'Enter') {
      this.moveNext(1);
      event.preventDefault();
    }
  }

  // need to set default on field and make mobile statelesss
  pauseForm = (pauseFormScroll) => {
    // should use to set the overflow instead of from props more consistent with Form
    //   this.setState({ pauseFormScroll });
  }

  moveTo = (index) => {



    const { height, topFieldOffset } = this.props;
    if (index === this.wrapperRefs.length) {
      const ref = this.wrapperRefs[this.wrapperRefs.length - 1];
      const field = ref.current;
      //field.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});



      this.thisOne.scrollTo({
        top: this.thisOne.scrollHeight,
        behavior: 'smooth',
      });
    }

    const nextIndex = index > this.wrapperRefs.length - 1 ? -1 : index;

    if (nextIndex > -1) {
      const ref = this.wrapperRefs[nextIndex];
      const field = ref.current;
      const fieldID = field.getAttribute('data-fieldid')
      const remainingSpace = (height / 2) - field.offsetHeight;

      //const topMargin = height / 4

      const topAdjustment = topFieldOffset > remainingSpace ? topFieldOffset : remainingSpace;

      const position = field.offsetTop - topAdjustment




      //offsetTop is the current position
      //topOffset distance to position field when move from top of screen






      //field.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      this.thisOne.scrollTo({
        top: position,
        behavior: 'smooth',
      })
    }
  }

  moveNext = (direction) => {

    /* if (this.activeIndex === 1 && direction < 0) {
 
       this.thisOne.scrollTo({
         top: 0,
         behavior: 'smooth',
       });
     }*/
    const nextIndex = this.activeIndex + (1 * direction);
    this.moveTo(nextIndex);
  }

  onWheel = (event) => {
    const { clickOutside } = this.props;
    const { deltaY } = event;
    if (deltaY < 0) {
      clickOutside();
    }
  }

  resize = (height) => {
    const first = this.wrapperRefs[0];
    if (first) {
      const firstRec = first.current.getBoundingClientRect();
      const rectHeight = firstRec.height;
      const topOffset = ((height / 2) - (rectHeight / 2));
      //makeing set from top not in middle add back later in options
      //this.setState({ topOffset });
    }
  }

  handleScroll = (event) => {
    // clientHeight is not the same as height, does it matter?
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const { height } = this.props
    // const topMargin = clientHeight * 0.35;
    // const bottomMargin = clientHeight * 0.85;

    this.priorHeight = clientHeight;
    this.priorTop = 0;

    this.wrapperRefs.map((ref) => {
      //const { title } = ref.current;

      const { title } = ref.current;
      const fieldID = ref.current.getAttribute('data-fieldid')

      const position = ref.current.getBoundingClientRect().top;
      const rectHeight = ref.current.getBoundingClientRect().height;

      // const info = title.split('_');
      const info = fieldID.split('_');
      const topOffset = 0;
      const bottomOffset = 0;
      const top = ((clientHeight / 2) - (rectHeight)) - topOffset;
      const bottom = (top + rectHeight) + bottomOffset;
      const appearY = this.priorHeight + this.priorTop + 75;

      this.priorHeight = rectHeight;
      this.priorTop = top;

     /// console.log('POSITIONS: ' + title)
     // console.log('top: ' + top)
     // console.log('position: ' + position)
     // console.log('appearY: ' + appearY)
      if (position > (top - 0) && position < (appearY - 0)) {
      //  console.log(`${position} > (${top} - 0) && ${position} < (${appearY} - 0)`)
        ref.current.style.opacity = 1;
        this[`nodeField${info[1]}`].current.focus();
        this.activeIndex = parseInt(info[1]);
      //  console.log(title + ': focus')
      } else {
       // console.log(title + ': blur')
        ref.current.style.opacity = 0.2;
        this[`nodeField${info[1]}`].current.blur();
      }
    });

   // console.log('scrollTop: ' + scrollTop)
  //  console.log('lastScrollTop: ' + this.lastScrollTop)
    if (scrollTop - this.lastScrollTop > 0) {
      const diff = Math.ceil(scrollHeight - scrollTop);
     // console.log('diff: ' + diff)
     // console.log('clientHeight: ' + clientHeight)
      if (diff > clientHeight - 3 && diff < clientHeight + 3) {
        const { formEnd, fields } = this.props;
        formEnd(fields);
      }
    }
    this.lastScrollTop = scrollTop;
  }

  fieldClicked = (fieldNumber) => {
    const index = parseInt(fieldNumber) - 1;
    this.moveTo(index);
  }

  makeField = (attribute, index, field) => {
    const {
      color, handleUpdate, incomplete, clickOutside,
    } = this.props;
    const {
      fieldType, format, options, choices, type, conditionalValue, placeholder,
    } = field;

    const componentRef = this[`nodeField${index}`] = React.createRef();

    const value = this.props[attribute];

    // const hasError = incomplete.includes(index);

    /*let hasError = false;
    const indexOf = incomplete.indexOf(attribute);
    if (indexOf > -1) {
      hasError = true;
    }*/

    // uses innerRef becouse the componenets are wrapped withStyle
    switch (fieldType) {
      case 'textfield':
        return <Text index={index} value={value} placeholder={placeholder} hasFocus={index === 0} type={type} format={format} attribute={attribute} innerRef={componentRef} color={color} handleUpdate={handleUpdate} moveNext={this.moveNext} />;
      case 'option':
        return <Option index={index} value={value} attribute={attribute} innerRef={componentRef} options={options} color={color} handleUpdate={handleUpdate} moveNext={this.moveNext} conditionalValue={conditionalValue} />;
      case 'range':
        return <Range index={index} value={value} attribute={attribute} innerRef={componentRef} highest={8} color={color} handleUpdate={handleUpdate} moveNext={this.moveNext} />;
      case 'choice':
        return <Choice index={index} value={value} attribute={attribute} innerRef={componentRef} choices={choices} color={color} pause={this.pauseForm} handleUpdate={handleUpdate} moveNext={this.moveNext} />;
      default:
        return (null);
    }
  }

  makeFormFields = () => {
    //const { topOffset } = this.state;

    const {
      fields, height, classes, color, incomplete, showMissing, topFieldOffset,
    } = this.props;
    const fieldNames = Object.keys(fields);

    this.wrapperRefs = [];
    let index = 0;
    const arrayOfFields = [];
    const mainColor = color || '#FFFFFF';
    // const fieldMargin = height * 0.10;
    const fieldMargin = 75;



    // for (let key of fieldNames) {
    fieldNames.map((key) => {
      // for (const key of fieldNames) {
      const {
        label, fieldType, errorMessage, attribute, title,
      } = fields[key];

      const indexString = (index + 1).toString();
      const wrapperRef = React.createRef();
      // this.wrapperRefs.push(wrapperRef);
      const opacity = this.activeIndex === index ? 1 : 0.05;

      let hasError = false;
      const indexOf = incomplete.indexOf(attribute);
      if (indexOf > -1) {
        hasError = true;
      }


      arrayOfFields.push(
        <div key={index} onClick={() => this.fieldClicked(indexString)} data-fieldid={`${fieldType}_${index}`} title={title} ref={wrapperRef} style={{ marginTop: index === 0 ? topFieldOffset > 0 ? topFieldOffset : height / 2 : 0, marginBottom: fieldMargin, opacity }} className={classes.page}>
          <Grid className={classes.grid}>

            <Grid item>
              <Label label={label} color={mainColor} itemNumber={indexString} />
            </Grid>
            <Grid item>
              <Grid item className={classes.fieldGrid}>
                {this.makeField(key, index, fields[key])}
              </Grid>
            </Grid>
            {showMissing && hasError ? (
              <Grid item> <Grid className={classes.errorGrid}>
                <ErrorMessage message={errorMessage} />
              </Grid>       </Grid>
            ) : null}

          </Grid>
        </div>,
      );
      this.wrapperRefs.push(wrapperRef);
      index += 1;
    });
    // }

    return arrayOfFields;
  }

  render() {
    const { classes, overflow, height } = this.props;
    return (
      <div style={{ overflowY: overflow }} className={classes.root}>
        {this.makeFormFields()}
        <div style={{ width: '100%', height: height * 0.50 }} />
      </div>
    );
  }
}

BrowserForm.propTypes = {
  classes: PropTypes.object.isRequired,
  overflow: PropTypes.string,
  height: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  color: PropTypes.string,
  formEnd: PropTypes.func,
  clickOutside: PropTypes.func,
  handleUpdate: PropTypes.func.isRequired,
  incomplete: PropTypes.array.isRequired,
  topFieldOffset: PropTypes.number
};


BrowserForm.defaultProps = {
  formEnd: () => { },
  clickOutside: () => { },
  overflow: 'scroll',
  color: '#FFFFFF',
  topFieldOffset: 100,
};


export default withStyles(styles)(BrowserForm);