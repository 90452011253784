import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Option from './Option';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 300,
    backgroundColor: 'transparent',
  },
  optionContainer: {
    position: 'inline',
    maxHeight: 210,
    'overflow-y': 'scroll',
    marginTop: theme.spacing.unit,
  },
  label: {
    fontWeight: 'bold',
  },
  textField: {
    width: '100%',
  },
});


class Choice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsDisplay: 'none',
      searchString: '',
      disabled: false,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setState({ searchString: value });
    }
  }

    internalFocus = () => {
      const { disabled } = this.state;
      if (disabled) {
        return;
      }
      this.setState({ optionsDisplay: 'block' });
      this.props.pause(true);
    }


    focus = () => {
      const { clickOutside } = this.props
      this.setState({disabled: false})
    }
    blur = () => {
      const { value } = this.props;
      this.setState({ disabled: true, optionsDisplay: 'none', searchString: value || '' });
    }

    dismissOptions = () => {
      this.props.pause(false);
      this.blur();
      this.props.moveNext(1);
    }

    handleSearch = (field, value) => {
      this.setState({ searchString: value });
    }

    handleSelection = (field, value, inValid) => {
      this.props.handleUpdate(field, value);
    }

    handleMobileSelection = (field, value) => {
      const { disabled } = this.state;
      if (disabled) {
        return;
      }
      this.props.handleUpdate(field, value);
      this.props.pause(false);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.moveNext(1);
      }, 800);
    }

    makeMobileChoices = () => {
      const { choices, attribute } = this.props;
      const options = choices.map(choice => (
        <option key={`${attribute}${choice}`} value={choice}>
          {choice}
        </option>
      ));
      options.unshift(<option key={`${attribute}placeholder`} value="" disabled />);

      return options;
    }

    render() {
      const { optionsDisplay, searchString, disabled } = this.state;
      const {
        classes, color, attribute, choices, value, ...other
      } = this.props;

      let options = choices;
      if (searchString.length > 0) {
        options = choices.filter(choice => choice.toLowerCase().includes(searchString.toLowerCase()));
      }

      return (
        <div className={classes.root}>
          {isMobile

            ? (
              <Select
                native
                className={classes.textField}
                style={{ color }}
                value={value || ''}
                onChange={(event) => { this.handleMobileSelection(attribute, event.target.value); }}
                input={(
                  <Input
                    disabled={disabled}
                    inputProps={{ style: { fontSize: 28 }}}
                    endAdornment={(
                      <InputAdornment position="end">
                        {optionsDisplay === 'none' ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                      </InputAdornment>
)}
                  />
)}
              >
                {this.makeMobileChoices()}
              </Select>
            )
            : (
              <div>
                <div style={{ borderColor: color, borderWidth: 4 }}>
                  <Input
                    disabled={disabled}
                    className={classes.textField}
                    inputProps={{ style: { fontSize: 28 }}}
                    style={{ color }}
                    value={searchString}
                    onFocus={(event) => { this.internalFocus(); }}
                    onChange={(event) => { this.handleSearch(attribute, event.target.value); }}
                    endAdornment={(
                      <InputAdornment position="end">
                        {optionsDisplay === 'none' ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                      </InputAdornment>
)}
                  />
                </div>
                <div style={{ display: optionsDisplay }} className={classes.optionContainer}>
                  <Option
                    {...other}
                    attribute={attribute}
                    options={options}
                    color={color}
                    value={value}
                    handleUpdate={this.handleSelection}
                    moveNext={() => {}}
                    dismiss={this.dismissOptions}
                  />
                </div>
              </div>
            )
          }
        </div>
      );
    }
}


Choice.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Choice);
