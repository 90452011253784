
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

// take a number press for an entry

const styles = theme => ({
  root: {
    width: '100%',
    //maxWidth: 300,
    backgroundColor: 'transparent',
  },
  gridRoot: {
    flexGrow: 1,
  },
  optionGrid: {

  },
  // need a better way to show style based on device

  option: {
    backgroundColor: 'rgba(255,255,255,0.25)',
    borderWidth: theme.spacing.unit * 0.07,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',
   // backgroundColor: 'transparent',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.50)',
    },
  },
  optionStart: {
    backgroundColor: 'rgba(255,255,255,0.25)',
    borderWidth: theme.spacing.unit * 0.07,
    borderLeftWidth: theme.spacing.unit * 0.14,
    borderLeftRadius: 4,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',
   // backgroundColor: 'transparent',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.50)',
    },
  },
  optionEnd: {
    borderWidth: theme.spacing.unit * 0.07,
    borderRightWidth: theme.spacing.unit * 0.14,
    borderRightRadius: 4,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',
    backgroundColor: 'rgba(255,255,255,0.25)',
    textAlign: 'center',
    color: theme.typography.body2.color,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.50)',
    },
  },
  mobileOption: {
    backgroundColor: 'rgba(255,255,255,0.25)',
    borderWidth: theme.spacing.unit * 0.07,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',
   // backgroundColor: 'transparent',
    textAlign: 'center',
  },
  mobileOptionStart: {
    backgroundColor: 'rgba(255,255,255,0.25)',
    borderWidth: theme.spacing.unit * 0.07,
    borderLeftWidth: theme.spacing.unit * 0.14,
    borderLeftRadius: 4,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',
   // backgroundColor: 'transparent',
    textAlign: 'center',
  },
  mobileOptionEnd: {
    borderWidth: theme.spacing.unit * 0.07,
    borderRightWidth: theme.spacing.unit * 0.14,
    borderRightRadius: 4,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',
    backgroundColor: 'rgba(255,255,255,0.25)',
    textAlign: 'center',
    color: theme.typography.body2.color,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.50)',
    },
  },
  selectedOption: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    textAlign: 'center',
    color: theme.typography.body2.color,
    borderRadius: 4,
    borderWidth: theme.spacing.unit * 0.50,
    borderColor: theme.typography.body2.color,
    borderStyle: 'solid',
  },
  title: {
    margin: 'auto',
    color: theme.typography.body2.color,
    cursor: 'pointer',
  },
  more: {
    color: theme.typography.body2.color,
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: theme.typography.title.fontSize,
  },
});

class Range extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
  }

  focus = () => {
    this.setState({ disabled: false });
  }

  blur = () => {
    this.setState({ disabled: true });
  }

handleUpdate = (field, value) => {
  const { disabled } = this.state;
  const { moveNext } = this.props;
  if (disabled) {
    return;
  }
  
  this.props.handleUpdate(field, value);
  clearTimeout(this.timer);
  this.timer = setTimeout(() => {
    moveNext(1);
  }, 500);
};

makeIndex = (index, width, start, end) => {
  const {
    classes, attribute, value,
  } = this.props;
  const gotone = value === index;

  let optionClass = isMobile ? classes.mobileOption : classes.option;
  if (start) {
    optionClass = isMobile ? classes.mobileOptionStart : classes.optionStart;
  } else if (end) {
    optionClass = isMobile ? classes.mobileOptionEnd : classes.optionEnd;
  }

  let indexString = `${index}`
  if(end){
    indexString = <span>{index}<span className={classes.more}>{'+'}</span></span>
  }

  return (
    <Grid key={index} item>
      <Paper
        square
        style={{ width, height: width }}
        className={gotone ? classes.selectedOption : optionClass}
        onClick={() => { this.handleUpdate(attribute, index); }}
      >
        <Typography
          className={classes.title}
          style={{ marginTop: 10 }}
          variant="h6"
        >
          {end ? `${index} +` : index}
        </Typography>
      </Paper>
    </Grid>
  );
}

makeIndexes = () => {
  const { highest } = this.props;
  const options = [];

  for (let index = 1; index < highest + 1; index++) {
    const option = this.makeIndex(index, 50, index === 1, index === highest);
    options.push(option);
  }

  return options;
}

render() {
  const { classes } = this.props;
  return (

    <div className={classes.root}>
      <Grid container className={classes.gridRoot}>
        <Grid item xs={12}>
          <Grid
            container
            className={classes.optionGrid}
            alignItems="center"
            direction="row"
            justify={isMobile ? 'center' : 'flex-start'}
          >
            {this.makeIndexes()}
          </Grid>
        </Grid>
      </Grid>
    </div>);
  }
}


Range.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Range);
