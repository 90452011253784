
import React from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';

const BottomPopup = ({ ...props }) => {
  const {
    children, open, height, shadow,
  } = props;



  const setStyle = (y) => {
    const motionStyle = {
      position: 'absolute',
      width: '100%',
      height,
      WebkitTransform: `translate3d(0, ${y + 20}px, 0)`,
      transform: `translate3d(0, ${y + 20}px, 0)`,
    };
  
    let shadowStyle = {};
  
    if (shadow > 0) {
      shadowStyle = {
        WebkitBoxShadow: '0 -1px 4px 1px rgba(0,0,0,0.25)',
        MozBoxShadow: '0 -1px 4px 1px rgba(0,0,0,0.25)',
        boxShadow: '0 -1px 4px 1px rgba(0,0,0,0.25)',
      }
    }
  
    const style = { ...motionStyle, ...shadowStyle };

    return style;
  };

  
  return (
    <div style={{ height: '100%' }}>
      <Motion style={{ y: spring(open ? height : 0) }}>
        {({ y }) => (
          <div
            style={setStyle(y)}
          >
            {children}
          </div>
        )}
      </Motion>
    </div>
  );
};

export default BottomPopup;

BottomPopup.propTypes = {
  children: PropTypes.object,
  open: PropTypes.bool,
  height: PropTypes.number,
//  shadow: PropTypes.number,
};

BottomPopup.defaultProps = {
  open: true,
  height: 50,
  children: [],
 // shadow: 0,
};
