import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Lock from '@material-ui/icons/Lock';
import Progress from './Progress';


const styles = theme => ({
  root: {
    maxHeight: '40%',
  },
  leftItem: {
    //paddingLeft: theme.spacing.unit * (isMobile ? 2 : 8),
    paddingLeft: '10%',
  },
  middleItem: {
    textAlign: 'center',
    height: '100%',
    paddingTop: isMobile ? theme.spacing.unit * 2.5 : 0,
  },
  rightItem: {
    //paddingRight: theme.spacing.unit * (isMobile ? 2 : 8),
    paddingRight: '10%',
  },
  button: {
    margin: theme.spacing.unit * 0.25,
  },
  largeIcon: {
    width: 15,
    height: 15,
    verticalAlign: 'baseline',
  },
  smallIcon: {
    width: 10,
    height: 10,
    verticalAlign: 'baseline',
  },
});

const Secure = ({ ...props }) => {
  const { classes, variant, isSpanish } = props;

  return (
    <Grid
      container
      className={classes.middleItem}
      spacing={16}
      alignItems={isMobile ? 'flex-end' : 'flex-end'}
      direction="row"
      justify="center"
   //   style={{ paddingBottom: 40 }}
   
    >
      <Grid item>
        <Typography variant={variant}>
          <Lock className={isMobile ? classes.smallIcon : classes.largeIcon} />
          {isSpanish === true ? 'La informacion es segura' : 'Information is secure'}
        </Typography>
      </Grid>
    </Grid>
  );
};



class Footer extends Component {
  render() {
    const {
      classes, width, backgroundColor, moveNext, completed, transparentButtons,
    } = this.props;


    return (
      <div style={{ height: '100%' }}> 
        <Grid container direction="column">
          <Grid item>

            <Grid container className={classes.root} style={{ backgroundColor }}>

              <Grid item xs={isMobile ? 6 : 4}>
                <Grid
                  container
                  spacing={16}
                  className={classes.leftItem}
                  alignItems={isMobile ? 'center' : 'center'}
                  direction="row"
                  justify="flex-start"
                >
                  <Grid item>
                    <Progress completed={completed} width={width < 550 ? 100 : 150} />
                  </Grid>
                </Grid>
              </Grid>

              {isMobile ? null : (
                <Grid item xs={4}>
                  <Secure {...this.props} variant="caption" />
                </Grid>
              )}

              <Grid item xs={isMobile ? 6 : 4}>
                <Grid
                  container
                  spacing={16}
                  className={classes.rightItem}
                  alignItems={isMobile ? 'flex-end' : 'center'}
                  direction="row"
                  justify="flex-end"
                >
                  <Grid item className={classes.rightItem}>

                    {transparentButtons > 0 ? (
                      <IconButton onClick={() => { moveNext(-1); }}>
                        <KeyboardArrowUp color="primary" />
                      </IconButton>)
                      : (<Button
                        onClick={() => { moveNext(-1); }}
                        className={classes.button}
                        size="small"
                        color="secondary"
                        variant="contained"
                      >
                        <KeyboardArrowUp color="primary" />
                      </Button>
                      )}


                    {transparentButtons > 0 ? (
                      <IconButton onClick={() => { moveNext(1); }}>
                        <KeyboardArrowDown color="primary" />
                      </IconButton>)
                      : (<Button
                        onClick={() => { moveNext(1); }}
                        className={classes.button}
                        size="small"
                        color="secondary"
                        variant="contained"
                      >
                        <KeyboardArrowDown color="primary" />
                         </Button>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.root}>
                {isMobile ? (
                  <Grid item xs={12}>
                    <Secure {...this.props} variant="caption" />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}


Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  moveNext: PropTypes.func,
  completed: PropTypes.number,
  // transparentButtons: PropTypes.number,
};


Footer.defaultProps = {
  moveNext: () => {},
  completed: 0,
  backgroundColor: 'transparent',
  // transparentButtons: 0,
};


export default withStyles(styles)(Footer);
