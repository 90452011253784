import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  buttonTextOverride: {
    fontWeight: 'bold',
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: theme.typography.h5.fontSize,
  },
  button: {
    width: 20,
    height: 20,
    marginLeft: theme.spacing.unit,
  },
  buttonCssRoot: {
    backgroundColor: 'rgba(255,0, 0, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(255,0, 0, 0.1)',
    },
  },
  fields: {
    marginTop: theme.spacing.unit,
  },
  message: {
    backgroundColor: 'rgba(255,0, 0, 1)',
    maxWidth: 400,
    marginTop: theme.spacing.unit * 2,
  },
  messageText: {
    paddingLeft: theme.spacing.unit,
    verticalAlign: 'middle',
  },
});

const Submit = ({ ...props }) => {
  const {
    classes, incomplete, selectedOk, showMissing, showMissingText, goToFirstError, isSpanish
  } = props;

  const missingString = incomplete.join(', ');

  return (
    <div style={{ textAlign: "center", height: "100%" }}>
      <Button
        style={{ height: 75, width: isSpanish === true ? 250 : 225 }}
        onClick={selectedOk}
        color="secondary"
        variant="contained"
        classes={{
          root: classes.buttonTextOverride,
        }}
      >
        {/*isSpanish ? "Tomar Cuota" : 'Get Quote'*/}
        {"Get Quote"}
      </Button>

      {showMissing ? (
        <Paper className={classes.message}>
          <Typography variant="body2" className={classes.messageText}>
            {showMissingText}
            <Button
              className={classes.button}
              classes={{
                root: classes.buttonCssRoot,
              }}
              variant="contained"
              color="secondary"
              onClick={goToFirstError}
            >
              {"Ok"}
            </Button>
          </Typography>
        </Paper>
      ) : null}
      {/* <Typography variant={'body2'}> {missingString}  </Typography> */}
    </div>
  );
};


export default withStyles(styles)(Submit);

Submit.propTypes = {
  open: PropTypes.bool,
  height: PropTypes.number,
  shadow: PropTypes.number,
  incomplete: PropTypes.array,
};

Submit.defaultProps = {
  open: true,
  height: 50,
  incomplete: [],
  shadow: 0,
};
