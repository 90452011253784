import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import validator from 'email-validator';
import { isMobile, isIOS, isSafari } from 'react-device-detect';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import keyboard from '../../../assets/img/sonic_keyboard_icon.png';
import invalidPhones from '../invalid-phones'

function PhoneMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}





const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    backgroundColor: 'transparent',
  },
  gridRoot: {
    flexGrow: 1,
  },
  optionGrid: {

  },
  keyboard: {
    opacity: 0.5,
    marginTop: 5,
    marginRight: 5,
  },
  table: {
    width: '100%',
  },
  tableCell: {
    padding: 0,
    border: 0,
    verticalAlign: 'middle',
  },
  textFieldOverlay: {
    width: '100%',
    height: 50,
   // backgroundColor: theme.palette.primary,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.typography.body2.color,
    borderStyle: 'dashed',
    textAlign: 'right',
  },
  textFieldOverlayCompleted: {
    width: '100%',
    height: 50,
 //   backgroundColor: 'rgba(255,255,255,.10)',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.typography.body2.color,
    borderStyle: 'dashed',
    textAlign: 'left',
  },
  textFieldOverlayText: {
    marginLeft: theme.spacing.unit,
  },
  keyboardIcon: {
    fontSize: 60,
    margin: 0,
  },
});


class Text extends Component {
  constructor(props) {
    super(props);
    this.lastDoneEvent = 0;
    this.lastOnFocusOut = 0;
    this.textFieldRef = React.createRef();
    this.state = {
      showOverlay: true,
      displayTextField: 'none',
      displayOverlay: 'block',
      errorMessage: '',
      textmask: '(1  )    -    ',
      customMask: false,
      disabled: true,
    };
  }

  componentDidMount() {
    const { format } = this.props;
    if (format === 'phone') {
      this.setState({ customMask: true });
    }
  }

    onKeydown = (event) => {
      const { key, timeStamp } = event;
      if (isMobile && key === 'Enter') {
        this.actionNextOnMobile();
      }
    }

    onFocusOut = (event) => {
      if (isIOS) {
        this.actionNextOnMobile();
      }
    }

    actionNextOnMobile = (timeStamp) => {
      const { moveNext } = this.props;
      if (timeStamp - this.lastMouseEvent < 1000) {
        return;
      }
      this.lastDoneEvent = timeStamp;
      this.blur();
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        moveNext(1);
      }, 800);
    }

    handleUpdate = (field, value) => {
      const { format, handleUpdate } = this.props;

      const updatedValue = value.trim();

     // const updatedValue = value

      if (updatedValue === '') {
        handleUpdate(field, updatedValue);
        return;
      }


      switch (format) {
        case 'phone':
          const phoneNumber = updatedValue.replace(/\D/g, '');
          const length = phoneNumber.length;
  
          if (!phoneNumber) {
            return;
          }
          
          if (length < 10) {
            handleUpdate(field, phoneNumber, true);
          } else if (length > 10) {
            handleUpdate(field, phoneNumber.toString().slice(0, -1), true);
          } else {
            if(invalidPhones.includes(phoneNumber)){
              handleUpdate(field, phoneNumber, true);
            }else{
              handleUpdate(field, phoneNumber, false);
            }
          }
          break;
        case 'email':
          const isValidEmail = validator.validate(updatedValue);
          handleUpdate(field, updatedValue, !isValidEmail);
          break;
        case 'postal':
    
         
          const isNumber = parseInt(updatedValue);

          if (isNumber > -1) {
            const total = updatedValue.length;

            if (total < 5) {
              handleUpdate(field, updatedValue, true);
            } else if (total > 5) {
              handleUpdate(field, updatedValue.slice(0, -1));
            } else {
              handleUpdate(field, updatedValue);
            }
          }
          break;
        default:
          handleUpdate(field, updatedValue);
          break;
      }
    }

    focus = () => {
      const { customMask } = this.state;
      if (isMobile) {
  
        // document.addEventListener('keydown', this.onKeydown);
        // document.addEventListener('focusout', this.onFocusOut);
        return;
      } if (customMask) {
 
       // console.log('this.textFieldRef')
      //  console.log(this.textFieldRef)
        if(this.textFieldRef.current){
          const { inputElement } = this.textFieldRef.current
          if(inputElement){
            const { focus } = inputElement
            if(focus){
              this.textFieldRef.current.inputElement.focus();
            }
          }else{
            this.textFieldRef.current.focus();
          }
        }
      } else {
        this.textFieldRef.current.focus();
      }

      this.setState({ disabled: false });
    }

    blur = () => {
      const { customMask } = this.state;
      const { displayTextField } = this.state;
     
        if(this.textFieldRef.current){
          if (customMask) {
            const { inputElement } = this.textFieldRef.current
            if(inputElement){
              const { blur } = inputElement
              if(blur){
                this.textFieldRef.current.inputElement.blur();
              }
            }
          } else {
            const { blur } = this.textFieldRef.current
            if(blur){
              this.textFieldRef.current.blur();
            }
          }
        }


      if (displayTextField === 'block') {
        this.setState({ disabled: true, displayTextField: 'none', displayOverlay: 'block' });
      } else {
        this.setState({ disabled: true });
      }

      // document.removeEventListener('keydown', this.onKeydown);
      // document.removeEventListener('focusout', this.onFocusOut);
    }

    toggleOverlay = () => {
      const { displayTextField, customMask } = this.state;
      const { pause } = this.props;
      if (displayTextField === 'none') {
        this.setState({ displayTextField: 'block', displayOverlay: 'none' }, () => {
        
         /* if (customMask) {
            this.textFieldRef.current.inputElement.focus();
          } else {
            this.textFieldRef.current.focus();
          }*/
         
          if(this.textFieldRef.current){
           
            if (customMask) {
              const { inputElement } = this.textFieldRef.current
              if(inputElement){
                const { focus } = inputElement
                if(focus){
                  this.textFieldRef.current.inputElement.focus();
                }
              }else {
                const { focus } = this.textFieldRef.current
                if(focus){
                  this.textFieldRef.current.focus();
                }
              }
            } else {
              const { focus } = this.textFieldRef.current
              if(focus){
                this.textFieldRef.current.focus();
              }
            }
          }
  
          pause(true);
        });
      } else {
        pause(false);
        this.setState({ displayTextField: 'none', displayOverlay: 'block' });
      }
    }

    render() {
      const {
        displayOverlay, displayTextField, disabled,
      } = this.state;
      const {
        classes, attribute, format, color, value, type, hasFocus, showError, errorMessage, placeholder,
      } = this.props;

    
  
      // const maxWidth = isMobile ? '40%' : 300;

      return (
        <div>
          {isMobile
            ? (
              <div
                className={value === '' || !value ? classes.textFieldOverlay : classes.textFieldOverlayCompleted}
                style={{ display: displayOverlay }}
                onClick={() => { this.toggleOverlay(); }}
              >
                {value === '' || value === null || value === undefined
                  ? <img className={classes.keyboard} src={keyboard} height="35" />
                  : (
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tableCell}>

                            <Typography className={classes.textFieldOverlayText} color="primary" variant="h6">
                              {value}
                            </Typography>

                          </TableCell>
                        </TableRow>

                      </TableBody>
                    </Table>
                  )}
              </div>
            )
            : null }
          
          {/* this a new error you can't pass null into */}
          {format === 'phone' ? 
          <Input
            fullWidth
            disabled={isMobile ? false : disabled}
            autoFocus={hasFocus}
            disableUnderline={!isMobile}
            inputRef={this.textFieldRef}
            placeholder={placeholder}
            type={type}
            value={value === '' || value === null || value === undefined ? ' ' : value}
            inputComponent={PhoneMaskCustom}
            inputProps={{
              style: {
                color, width: '100%', fontSize: 28, maxWidth: 600,
              },
            }}
            style={{ color, display: isMobile ? displayTextField : 'block'}}
            onChange={(event) => { this.handleUpdate(attribute, event.target.value); }}
          /> :
          <Input
          fullWidth
          autoComplete='off'
          disabled={isMobile ? false : disabled}
          autoFocus={hasFocus}
          disableUnderline={!isMobile}
          inputRef={this.textFieldRef}
          placeholder={placeholder}
          type={type}
          value={value === '' || value === null || value === undefined ? ' ' : value.toString()}

          inputProps={{
            style: {
              color, width: '100%', fontSize: 28, maxWidth: 600,
            },
          }}
          style={{ color, display: isMobile ? displayTextField : 'block'}}
          onChange={(event) => { this.handleUpdate(attribute, event.target.value); }}
        /> }

        </div>
      );
    }
}
// <input type="text" tabindex="-1" name="f1"> need to add to prevent tab from ios keyboard. can check user agent for ios or ismobile i think.

Text.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  moveNext: PropTypes.func,
};

Text.defaultProps = {
  type: 'text',
  moveNext: () => {},
};


export default withStyles(styles)(Text);
