
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slider from 'react-scroll-by-page';
import { isMobile, isIOS } from 'react-device-detect';
import Grid from '@material-ui/core/Grid';
import Label from './fields/Label';
import Option from './fields/Option';
import Choice from './fields/Choice';
import Range from './fields/Range';
import Text from './fields/Text';
import ErrorMessage from './ErrorMessage';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  page: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  section: {
    width: '100%',
  },
  container: {
    width: '100%',
  },
  grid: {
    width: '90%',
  },
  fieldGrid: {
    marginLeft: theme.spacing.unit * 2,
    width: '90%',
    textAlign:'center',
  },
  errorGrid: {
    marginBottom: theme.spacing.unit * 2,
    marginLeft: 0,
    maxWidth: '300',
  },
});


class Form extends Component {
  constructor(props) {
    super(props);
    this.activeIndex = 0;
    this.lastActiveIndex = 0;
    this.lastKeyEvent = 0;
    this.lastClick = 0;
    this.state = {
      pauseFormScroll: false,
    };
  }

  componentDidMount() {

    document.addEventListener('touchstart', this.onTouchStart);
    document.addEventListener('touchmove', this.onTouchStart);
    document.addEventListener('keydown', this.onKeydown);
    document.addEventListener('focusout', this.onFocusOut);
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.onTouchStart);
    document.addEventListener('touchmove', this.onTouchStart);
    document.removeEventListener('keydown', this.onKeydown);
    document.removeEventListener('focusout', this.onFocusOut);
  }

  onAnimate = (event) => {
    this.dismissActiveField();
  }

  resize = () => {
    // handle rotation
  }

  onTouchStart = (event) => {

    const { pageY } = event;
    const { clickOutside, height, bottomPopUpHeight, frameID } = this.props;

    if (pageY < height + bottomPopUpHeight) {
      clickOutside();
    }

    const { referrer } = document; 
    if(referrer){
     window.parent.postMessage(JSON.stringify({ frameID }),  `${referrer}`);
    }
  }

  onTouchMove = (event) => {
    const { pageY } = event;
    const { clickOutside, height, bottomPopUpHeight, frameID } = this.props;

    if (pageY < height + bottomPopUpHeight) {
      clickOutside();
    }
    
    const { referrer } = document; 
    if(referrer){
      window.parent.postMessage(JSON.stringify({ frameID }),  `${referrer}`);
    }
  }

  /*
  handleUpdate = (field, value, inValid) => {
    const { incomplete } = this.state;

    const temp = {};
    temp[field] = value;

    if (inValid) {
      const newInc = [...incomplete, field];
      temp.incomplete = newInc;
    } else {
      const index = incomplete.indexOf(field);
      if (index > -1) {
        const newInc = incomplete.splice(index, 1);
        temp.incomplete = newInc;
      }
    }
    this.setState(temp);
  }*/

  // need to move this to npm module for form
  onKeydown = (event) => {
    //console.log('onKeydown')
   // console.log(event)
    if(event){
      const { key, timestamp, timeStamp } = event;
    /*  if(timestamp){
        console.log('timestamp')
        console.log(timestamp)
        this.lastKeyEvent = timestamp;
      }else if (timeStamp){
        console.log('timeStamp')
        console.log(timeStamp)
        this.lastKeyEvent = timeStamp;
      }*/
     // this.lastKeyEvent = Date.now();
      if (isMobile && key === 'Enter') {
        this.lastKeyEvent = Date.now();
        this.actionNext();
      }
    }

  }

  onFocusOut = (event) => {
    const { timeStamp } = event;
    //console.log('onFocusOut')
    //console.log(event)
    //console.log(timeStamp)
   // console.log(this.lastKeyEvent)
   // console.log(Date.now() - this.lastKeyEvent)
   /* if (timeStamp - this.lastKeyEvent > 1000) {
      return;
    }*/
    
    if (Date.now() - this.lastKeyEvent < 1000) {
      return;
    }

    if (isIOS) {
      this.actionNext();
    }
  }

  actionNext = () => {
    //console.log('actionNext')
    this.pauseForm(false);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {

      this.moveNext(1);
    }, 800);
  }

  pauseForm = (pauseFormScroll) => {
    this.setState({ pauseFormScroll });
  }

  dismissActiveField = () => {
    //console.log('dismissActiveField')
    //console.log(this[`nodeField${this.activeIndex}`])
    //console.log(this[`nodeField${this.activeIndex}`].current)

    const input = this[`nodeField${this.activeIndex}`].current;



    if (typeof input.blur === 'function') {
      //console.log('blur')
      input.blur();
    }
  }

  animationFinished = (activeIndex) => {

    const input = this[`nodeField${activeIndex}`].current;

    // this.animating = false;
    if (typeof input.focus === 'function') {
      input.focus();
    }

    const { fieldCount, formEnd, fields } = this.props;

    if (activeIndex === fieldCount - 1) {
      if (this.activeIndex === activeIndex) {

        formEnd(fields);
      }
    }

    this.activeIndex = activeIndex;
    this.setState({ animating: false });
  }

  moveTo = (index) => {
    this.slider.scrollTo(index, false);
    // this.slider.previous(false)
  }

  moveNext = (direction, keypress) => {

    //console.log('moveNext')
   // console.log(keypress)
    if (keypress) {
      const now = Date.now();
      if (now - this.lastClick < 1000) {
        return;
      }
      this.lastClick = now;
      const { animating } = this.state;
      if (animating) {
        return;
      }
      this.setState({ animating: true });
    }

    if (direction < 0) {
      this.slider.previous(true);
      this.activeIndex = this.activeIndex > 0 ? this.activeIndex - 1 : 0;
    } else {
      const { fieldCount, formEnd, fields } = this.props;
      if (this.activeIndex === fieldCount - 1) {
        formEnd(fields);
      } else {
        this.slider.next(true);
        this.activeIndex = this.activeIndex + 1;
      }
    }
  }

  makeField = (attribute, index, field) => {
    const { color, handleUpdate } = this.props;
    const {
      fieldType, format, options, choices, type, questionTopPadding,
    } = field;
    const componentRef = this[`nodeField${index}`] = React.createRef();
    const value = this.props[attribute];

    // const hasError = incomplete.includes(index);
    // uses innerRef becouse the componenets are wrapped withStyle
    switch (fieldType) {
      case 'textfield':
        return <Text index={index} value={value} hasFocus={index === 0} type={type} format={format} attribute={attribute} innerRef={componentRef} color={color} pause={this.pauseForm} handleUpdate={handleUpdate} moveNext={this.moveNext} />;
      case 'option':
        return <Option index={index} value={value} attribute={attribute} innerRef={componentRef} options={options} color={color} handleUpdate={handleUpdate} moveNext={this.moveNext} /> ;
      case 'range':
        return <Range index={index} value={value} attribute={attribute} innerRef={componentRef} highest={8} color={color} handleUpdate={handleUpdate} moveNext={this.moveNext} />;
      case 'choice':
        return <Choice index={index} value={value} attribute={attribute} innerRef={componentRef} choices={choices} color={color} pause={this.pauseForm} handleUpdate={handleUpdate} moveNext={this.moveNext} />;
      default:
        return (null);
    }
  }

 makeFormFields = () => {
   const {
     classes, fields, color, showMissing, incomplete, questionTopPadding,
   } = this.props;
   const fieldNames = Object.keys(fields);

   let index = 0;
   const arrayOfFields = [];
   const mainColor = color || '#FFFFFF';


   for (const key of fieldNames) {
     const { label, errorMessage, attribute } = fields[key];

     let hasError = false;
     const indexOf = incomplete.indexOf(attribute);
     if (indexOf > -1) {
       hasError = true;
     }

     const indexString = (index + 1).toString();
     arrayOfFields.push(
       <div key={index} className={classes.page}>
         <Grid className={classes.grid}>
           {showMissing && hasError ? (
           <Grid className={classes.errorGrid}>
            <ErrorMessage message={errorMessage} />
          </Grid>
         ) : null }
           <Grid>
           <div style={{ paddingTop: questionTopPadding ? 40 : 0 }}>
             <Label label={label} color={mainColor} itemNumber={indexString} />
             </div>
           </Grid>
           <Grid className={classes.fieldGrid}>
             {this.makeField(key, index, fields[key])}
           </Grid>
         </Grid>
       </div>,
     );
     index += 1;
   }
   return arrayOfFields;
 }

 render() {
   const { classes, questionTopPadding } = this.props;
   const { pauseFormScroll } = this.state;

   return (
     <div className={classes.root}>
       <Slider
         ref={(ref) => { (this.slider = ref); }}
         preventScroll={pauseFormScroll}
         preventTouch={pauseFormScroll}
         onPage={this.animationFinished}
         onScroll={this.onAnimate}
         isMobile={isMobile}
         keyActionsNext={isMobile ? ['ArrowDown'] : ['ArrowDown', 'Enter']}
       >

         {this.makeFormFields()}

       </Slider>
     </div>
   );
 }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  incomplete: PropTypes.array.isRequired,
  color: PropTypes.string,
  formEnd: PropTypes.func,
  frameID: PropTypes.string,
};

Form.defaultProps = {
  formEnd: () => {},
  color: '#FFFFFF',
  frameID: 'none',
};


export default withStyles(styles)(Form);
