import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import Form from "./components/form";
//import { withAuthenticator } from 'aws-amplify-react';
//import logo from './logo.svg';
//import './App.css';

Amplify.configure(aws_exports);

class App extends Component {
  
  render() {
  //  console.log(this.props)
    return <Form {...this.props} />

    
    /*return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    );*/
  }
}

export default App;
//export default withAuthenticator(App, true);
