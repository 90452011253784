'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _support = require('./support');

var _support2 = _interopRequireDefault(_support);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = _support2.default; //based on https://github.com/NdYAG/react-onepage