
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPixel from 'react-facebook-pixel';
import { deviceDetect, isMobile } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import publicIp from 'public-ip'
import Grid from '@material-ui/core/Grid';
import { disableBodyScroll } from 'body-scroll-lock';
import uuidv4 from 'uuid/v4';
import Form from './Form';
import BrowserForm from './BrowserForm';
import Fields from './fields';
import Footer from './footer';
import BottomPopup from '../../lib/BottomPopup';
import Submit from './Submit';
import Processing from './Processing';
import Complete from './Complete';
import { ConsoleLogger } from '@aws-amplify/core';
import packageJson from '../../../package.json'

const formVersion = packageJson.version;
console.log(formVersion)

// Little helpers ...
const url = (name, wrap = false) => `${
  wrap ? 'url(' : ''
}https://s3.amazonaws.com/form.sonicwebdev.com/backgroundImages/${name}.png${
  wrap ? ')' : ''
}`;

const uniqueArray = arrArg => arrArg.filter((elem, pos, arr) => arr.indexOf(elem) == pos);

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    zIndex: -1,
    flexGrow: 1,
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
  grid: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    textAlign: 'center',
  },
  finish: {
    position: 'absolute',
    width: '100%',
  },
});


class Home extends Component {
  state = {
    direction: "row",
    justify: "center",
    alignItems: "center",
    open: false,
    height: 0,
    fields: {},
    incomplete: [],
    completed: 0,
    showMissing: false,
    showMissingText: 'Please complete the required fields:',
    formProcessing: false,
    formCompleted: false,
    isSpanish: false,
  };

  componentDidMount() {
    this.updateWindowDimensions();

    const sonicSubmissionID = uuidv4();

    let { settings, parentSettings } = this.props;
    const { adCode, adSource } = parentSettings;
    //const { isSpanish: isSpanishString } = settings
    //  const isSpanish = isSpanishString === "true"
    // console.log(settings)
    // console.log(parentSettings);
    //  const theFields = isSpanish ? Fields['spanish'] : Fields['english']
    const theFields = Fields["english"];
    const fields = { ...theFields };
    // const { location } = settings
    //  const { adCode, adSource } = parentSettings

    // const fieldCount = Object.keys(fields).length;
    const fieldCount = this.getFilteredFieldCount(fields);

    this.setState({
      ...settings,
      isSpanish: false,
      ...parentSettings,
      fields,
      fieldCount,
      sonicSubmissionID,
    });
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("message", this.addTCPACert);
    if (isMobile) {
      disableBodyScroll(window);
    }

    const fboptions = {
      autoConfig: true,
      debug: false,
    };

    if (adSource === "facebook") {
      ReactPixel.init(adCode, fboptions);
      ReactPixel.pageView();
    } else if (adSource === "google") {
    } else {
      // ReactPixel.init('1900496376913132', fboptions);
      // ReactPixel.pageView()
    }

    this.setUserIP();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("message", this.addTCPACert);
  }

  setUserIP = async () => {
    try {
      const contactIP = await publicIp.v4();
      //  console.log(contactIP)
      this.setState({ contactIP });
    } catch (error) {
      console.error(error.stack);
    }
  };

  getUncompleted = (fields) => {
    const { incomplete } = this.state;
    const keys = Object.keys(fields);
    const inc = keys.filter((key) => {
      const { [key]: attribute } = this.state;
      if (!attribute) {
        //if (!(key in this.state)) {
        return true;
      }
      return false;
    });
    const arrayResult = uniqueArray(incomplete.concat(inc));
    return arrayResult;
  };

  getFilteredFieldCount = (fields) => {
    const filteredFields = this.filterFields(fields, true);
    const fieldCount = Object.keys(filteredFields).length;
    return fieldCount;
  };

  updateWindowDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (this.form) {
      this.form.resize(height);
    }
    this.setState({ height, width });
  };

  addTCPACert = (e) => {
    if (e.origin == window.location.origin) {
      if (typeof e.data === "object") {
        if ("tcpaCertID" in e.data) {
          this.setState({
            tcpaCertLink: `https://cert.trustedform.com/${e.data.tcpaCertID}`,
          });
        }
      }
    }
  };

  clickOutside = () => {
    const { open } = this.state;
    if (open) {
      this.form.pauseForm(false);
      this.setState({ open: false, overflow: "scroll" });
    }
  };

  goToFirstError = () => {
    const { incomplete, fields } = this.state;
    const keys = Object.keys(fields);
    this.form.pauseForm(false);
    console.log(incomplete);
    if (incomplete.length > 0) {
      const lastItem = incomplete[0];
      const index = keys.indexOf(lastItem);
           console.log(index);
      if (index > -1) {
        this.setState({ open: false, overflow: "scroll" }, () => {
          if (!isMobile) {
             console.log('should scroll');
            this.form.moveTo(index);
          }
        });
      }
    }
  };

  translatePayload = (payload) => {
    let { ageRange, condition, income, individual, terms } = payload;
    const { language } = Fields;
    const {
      individual: individualMap,
      ageRange: ageRangeMap,
      condition: conditionMap,
      terms: termsMap,
    } = language;

    const { [condition]: foundCondition } = conditionMap;
    const { [ageRange]: foundAgeRange } = ageRangeMap;
    const { [individual]: foundIndividual } = individualMap;
    const { [terms]: foundTerms } = termsMap;

    if (foundCondition) {
      condition = foundCondition;
    }
    if (foundAgeRange) {
      ageRange = foundAgeRange;
    }
    if (foundIndividual) {
      individual = foundIndividual;
    }
    if (foundTerms) {
      terms = foundTerms;
    }
    income = income.replace("Encima", "Above");
    income = income.replace("Abajo", "Below");

    return { ...payload, condition, ageRange, individual, income, terms };
  };

  goToDisconnectedPhone = (fields, incomplete) => {
  //  const { open } = this.state;
   // if (open) {
      incomplete.unshift("phone");
     //this.form.pauseForm(false);
      this.setState(
        {
          //open: false,
          showMissing: true,
          showMissingText: 'Please enter a valid phone',
          incomplete,
         // overflow: "scroll",
        },
        () => {
         // this.goToFirstError()
          // if (!isMobile) {
          //   const keys = Object.keys(fields);
          //   const index = keys.indexOf("phone");
          //   this.form.moveTo(index - 1);

          // }
        }
      );





      // if (!isMobile) {
      //   const keys = Object.keys(fields);
      //   const index = keys.indexOf("phone");
      //    console.log("goToDisconnectedPhone");
      //   console.log(index);
      //   this.form.moveTo(index - 1);
      // }
  //  }
  };

  submit = async () => {
    const {
      os,
      incomplete,
      fields,
      sonicSubmissionID,
      campaign,
      adCode,
      adSource,
      adBroker,
      condition,
      splitPolicy,
      household,
      region,
    } = this.state;

    const showMissing = incomplete.length > 0;
    this.setState({ showMissing });

    if (showMissing) {
      if (isMobile) {
        const keys = Object.keys(fields);
        const lastItem = incomplete[0];
        const index = keys.indexOf(lastItem);

        this.form.moveTo(index);
      }
      return;
    }

    const userAgent = deviceDetect();
    const { referrer } = document;
    let qualifier = "standard";
    if (splitPolicy === "Yes") {
      qualifier = "split_policy";
    }

    // REACT_APP_API_URL=https://scotjzyc4i.execute-api.us-east-1.amazonaws.com/prod/forms/sonic/v1
    const url = process.env.REACT_APP_API_URL;

    const formData = this.translatePayload({ ...this.state });

    // console.log(formData)

    const params = {
      method: "POST",
      body: JSON.stringify({
        ...formData,
        referrer,
        ...userAgent,
        qualifier,
        sonicSubmissionID,
        formVersion,
      }),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      this.setState({ formProcessing: true });
      const response = await fetch(url, params);
      this.setState({ formProcessing: false });
      if (response.status !== 200) {
        return Promise.reject(new Error(JSON.stringify(response)));
      }
      const result = await response.json();
      ///console.log(result)
      const { leadID, sonicPhoneResult, povertyLevel, phoneValid } = result;
      if (!phoneValid) {
        // incomplete.unshift("phone");
        // this.form.pauseForm(false);

        // if (isMobile) {
        // const keys = Object.keys(fields);
        //  const index = keys.indexOf("phone");
        // this.setState({
        //   showMissing: true,
        //   incomplete,
        // });
        this.goToDisconnectedPhone(fields, incomplete);
        //this.clickOutside();
        // this.form.moveTo(index);
        // this.goToFirstError();
        // }else{
        //   this.form.pauseForm(false);
        //   this.setState({
        //     showMissing: true,
        //     open: true,
        //     overflow: "hidden",
        //     incomplete,
        //   });

        // }
      } else if (leadID) {
        const tracking = {
          os,
          sonicPhoneResult,
          region,
          povertyLevel,
          household,
          campaign,
          adCode,
          adSource,
          adBroker,
          condition,
          formData,
        };
        this.setState({ formCompleted: true, tracking });
      } else {
        return Promise.reject(new Error(JSON.stringify(result)));
      }
    } catch (err) {
      //send error notification
      console.error(err);
      return Promise.reject(err);
    }
  };

  moveNext = (direction) => {
    this.form.moveNext(direction, true);
  };

  formEnd = (fields) => {
    const keys = Object.keys(fields);
    const incomplete = this.getUncompleted(fields);

    // let showMissing = true;

    const lastKey = keys[keys.length - 1];

    const lastKeyCompleted = incomplete.indexOf(lastKey);

    if (isMobile && lastKeyCompleted > -1) {
      return;
    }

    this.form.pauseForm(true);

    if (incomplete.length < 1) {
      const showMissing = false;
      this.setState({
        open: true,
        overflow: "hidden",
        incomplete,
        showMissing,
      });
    } else {
      this.setState({
        open: true,
        overflow: "hidden",
        incomplete,
      });
    }
  };

  handleUpdate = (field, value, inValid) => {
    const { incomplete, fields, isSpanish } = this.state;
    const language = isSpanish ? "spanish" : "english";

    // const { [field] : theField } = Fields[language]
    const { [field]: theField } = Fields["english"];

    // console.log(theField)
    const { clearOnChange, triggerTCPACert } = theField;

    const fieldKeys = Object.keys(fields);
    const temp = {};

    temp[field] = value;

    // console.log(clearOnChange)
    if (clearOnChange) {
      temp[clearOnChange] = null;
    }

    const index = incomplete.indexOf(field);
    const incompleteSlice = incomplete.slice();

    if (inValid || value === "" || value === null || value === undefined) {
      if (index > -1) {
        // incompleteSlice.splice(index, 1);
        //  temp.incomplete = incompleteSlice;
      } else {
        const newInc = [...incompleteSlice, field];
        temp.incomplete = newInc;
      }
    } else if (index > -1) {
      incompleteSlice.splice(index, 1);
      temp.incomplete = incompleteSlice;
    }

    const completed = fieldKeys.filter((key) => key in this.state);
    temp.completed = Math.round(
      ((completed.length + 1) / (fieldKeys.length + 1)) * 100
    );
    const fieldCount = this.getFilteredFieldCount(fields);
    temp.fieldCount = fieldCount;
    this.setState(temp);

    if (triggerTCPACert) {
      window.dataLayer.push({ event: "formReadyToSubmit" });
    }
  };
  verifyIfThen = (conditionalField, ifThenOptions) => {
    // console.log(conditionalField)
    const ifValue = this.state[conditionalField];
    // console.log(ifValue)
    const matchingCondition = ifThenOptions.filter((value) => {
      // console.log('value')
      // console.log(value)
      return ifValue === value;
    });
    // console.log(matchingCondition)
    return matchingCondition.length > 0;
  };

  filterFields = (fields, excludeConditionalField) => {
    const fieldKeys = Object.keys(fields);

    const filtered = {};

    for (const key of fieldKeys) {
      const field = fields[key];
      const {
        conditionalField,
        ifConditionalFields,
        ifThenOptions,
        conditionalOptions,
      } = field;
      if (!excludeConditionalField) {
        if (conditionalField) {
          const conditionalValue = this.state[conditionalField];
          if (
            conditionalValue === undefined ||
            conditionalValue === null ||
            conditionalValue === ""
          ) {
            break;
          } else {
            if (conditionalOptions) {
              field.options = field.conditionalOptions[conditionalValue];
            }
          }
        }
      }

      if (ifConditionalFields) {
        if (ifThenOptions) {
          if (
            ifThenOptions === undefined ||
            ifThenOptions === null ||
            ifThenOptions.length === 0
          ) {
          } else {
            let hasMatch = [];

            for (const conditional of ifConditionalFields) {
              const matched = this.verifyIfThen(
                conditional,
                ifThenOptions[conditional]
              );
              if (matched) {
                // console.log(conditional)
                //   console.log('matched')
                hasMatch.push(true);
              }
            }

            // console.log(hasMatch)
            //  console.log(ifConditionalFields)
            if (hasMatch.length !== ifConditionalFields.length) {
              continue;
            }

            /*const ifValue = this.state[conditionalField]
            console.log(ifValue)
            const matchingCondition = ifThenOptions.filter((value) => {
                return ifValue === value
            })
            console.log(matchingCondition)
            if(matchingCondition.length === 0){
              continue
            }*/
          }
        }
      }
      filtered[key] = field;
    }
    return filtered;
  };
  /*
  filterFields = (fields) => {
    const fieldKeys = Object.keys(fields);
    const filtered = {};
    for (const key of fieldKeys) {
      const field = fields[key];
      const { conditionalField } = field;
      if (conditionalField) {
        const conditionalValue = this.state[conditionalField];
        if (conditionalValue === undefined || conditionalValue === null || conditionalValue === '') {
          break;
        } else {
          field.options = field.conditionalOptions[conditionalValue];
        }
      }
      filtered[key] = field;
    }

    return filtered;
  }*/

  render() {
    const { classes } = this.props;
    // const { formProcessing, formCompleted } = commonStore;
    const { formProcessing, formCompleted, isSpanish } = this.state;

    const {
      tracking,
      topFieldOffset,
      frameID,
      width,
      alignItems,
      justify,
      showMissing,
      showMissingText,
      direction,
      loading,
      open,
      overflow,
      shadow,
      transparentButtons,
      fields,
      incomplete,
      completed,
      height,
      backgroundColor,
      color,
      backgroundImageName,
      backgroundImageSize,
      backgroundImageOpacity,
      backgroundPosition,
      campaign,
      ...other
    } = this.state;

    const { questionTopPadding, region } = this.state;

    const missing = incomplete.map((item) => {
      if (item in fields) {
        return fields[item].shortText;
      }
      return "";
    });

    const percentOfHeight = isMobile ? 0.65 : 0.6;

    const heightOfSubmission = height * percentOfHeight * -1;

    const filteredFields = this.filterFields(fields);

    let justifyBG = "center";
    if (isMobile) {
      if (backgroundPosition) {
        justifyBG = backgroundPosition;
      } else {
        justifyBG = "flex-start";
      }
    }

    let backgroundName = backgroundImageName;
    if (backgroundName) {
      const nameArray = backgroundName.split("_");
      //console.log(nameArray)
      if (nameArray.length > 1) {
        if (nameArray[1] === "states") {
          if (region) {
            backgroundName = `${region.toLowerCase()}_states`;
          }
        }
      }
    }

    if (formCompleted) {
      return (
        <Complete
          backgroundColor={backgroundColor}
          tracking={tracking}
          isSpanish={isSpanish}
        />
      );
    }

    return (
      <div>
        {formProcessing ? (
          <Processing backgroundColor={backgroundColor} />
        ) : null}
        <div
          style={{
            zIndex: -1,
            backgroundColor: backgroundColor || "transparent",
            width: "100%",
            height: "100%",
            position: "fixed",
          }}
        >
          <Grid container className={classes.gridRoot}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={16}
                className={classes.grid}
                alignItems="center"
                direction={isMobile ? "column" : "row"}
                justify={justifyBG}
              >
                <Grid item>
                  {backgroundName ? (
                    <img
                      alt="alt"
                      src={url(backgroundName)}
                      style={{
                        opacity: backgroundImageOpacity / 100,
                        width: `${backgroundImageSize}%`,
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              height: isMobile ? "85%" : "90%",
              opacity: open ? 0.5 : 1,
            }}
          >
            {isMobile ? (
              <Form
                {...other}
                frameID={frameID}
                bottomPopUpHeight={heightOfSubmission}
                showMissing={showMissing}
                clickOutside={this.clickOutside}
                formEnd={this.formEnd}
                innerRef={(ref) => {
                  this.form = ref;
                }}
                fields={filteredFields}
                height={height}
                color={color}
                incomplete={incomplete}
                handleUpdate={this.handleUpdate}
              />
            ) : (
              <BrowserForm
                {...other}
                topFieldOffset={parseInt(topFieldOffset || 100)}
                showMissing={showMissing}
                clickOutside={this.clickOutside}
                formEnd={this.formEnd}
                overflow={overflow}
                innerRef={(ref) => {
                  this.form = ref;
                }}
                fields={filteredFields}
                height={height * 0.95}
                incomplete={incomplete}
                handleUpdate={this.handleUpdate}
                color={color}
              />
            )}
          </div>
          <div
            style={{
              height: isMobile ? "15%" : "10%",
              width: "100%",
              marginBottom: isMobile && questionTopPadding ? 40 : 0,
            }}
          >
            <Footer
              width={width}
              backgroundColor={backgroundColor}
              transparentButtons={transparentButtons}
              moveNext={this.moveNext}
              completed={completed}
            />
          </div>
          <BottomPopup {...this.state} height={heightOfSubmission}>
            {/* <div style={{ backgroundColor }}> */}
            <div>
              <Grid container className={classes.root}>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={16}
                    style={{ height: heightOfSubmission * -1 }}
                    alignItems={alignItems}
                    direction={direction}
                    justify={justify}
                  >
                    <Submit
                      incomplete={missing}
                      selectedOk={this.submit}
                      showMissing={showMissing}
                      showMissingText={showMissingText}
                      goToFirstError={this.goToFirstError}
                      isSpanish={isSpanish}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </BottomPopup>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  //location: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);

/*

import Submission from './Submission';
import Errors from './Errors';
      submissionOverlay: 'none',
      errorsOverlay: 'none',
      {submissionOverlay === 'block' ? <Submission style={{ display: submissionOverlay }} /> : null }
             {submissionOverlay === 'block' ? <Submission style={{ display: submissionOverlay }} /> : null }
       {errorsOverlay === 'block' ? <Errors style={{ display: errorsOverlay }} /> : null }


*/
