import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import loading from '../../assets/img/loading.gif';




const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
});

const Processing = ({ ...props }) => {
  const { backgroundColor } = props;

  return (
    <div style={{
      zIndex: 9999999, backgroundColor: backgroundColor || 'transparent', width: '100%', height: '100%', position: 'fixed', textAlign: 'center',
    }}
    >
      <img style={{width: 150, height: 150, marginTop: 150 }} src={loading} />
    </div>
  );
};


export default withStyles(styles)(Processing);

Processing.propTypes = {
  open: PropTypes.bool,
};

Processing.defaultProps = {
  open: true,
};
