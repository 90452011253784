/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "sonic-form-20190122113859-hostingbucket-sonicform",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dc9intobc41rd.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:a5ffbc4f-8b63-464a-9ffa-05b27f13ca68",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PWi4z4rwf",
    "aws_user_pools_web_client_id": "5k54o5h8c3o8kpmmf3hv6al4q8",
    "oauth": {},
    "aws_mobile_analytics_app_id": "b2c0ad692c0a4ed2867b19b89ad44e27",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "sonicform-sonicform",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "sonicform",
            "endpoint": "https://3lkr49rdjh.execute-api.us-east-1.amazonaws.com/sonicform",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
